const data = {
  "Header": {
    "d1": `Cài đặt ngôn ngữ`,
    "d2": `Chuyển ngôn ngữ sang`,
    "d3": `Hủy`,
    "d4": `Xác nhận`
  },
  "Bacarat": {
    "d1": `Baccarat Cổ Điển`,
    "d2": `1.Quy tắc trò chơi`,
    "d3": `Trò chơi được chơi với 8 bộ bài (không bao gồm Joker), tổng cộng 416 lá bài;`,
    "d4": `"Người chơi" và "Nhà cái" mỗi người nhận hai lá bài, "Người chơi" nhận bài trước, tiếp theo là "Nhà cái" (thứ tự lộ bài giống nhau). Nếu vòng đầu tiên không xác định được người thắng cuộc, bài bổ sung sẽ được rút theo "quy tắc," với tối đa một lá bài bổ sung cho mỗi người để quyết định kết quả;`,
    "d5": `Nếu Người chơi hoặc Nhà cái đạt được 8 hoặc 9 điểm (chiến thắng Tự nhiên), trò chơi được giải quyết với bốn lá bài đầu tiên và không có lá bài bổ sung nào được rút;`,
    "d6": `Kết quả cuối cùng được xác định bằng cách so sánh chữ số đơn vị của tổng điểm của Người chơi và Nhà cái`,
    "d7": `2.Quy tắc kéo bài`,
    "d8": `Quy tắc Rút bài Sau khi lượt bài đầu tiên được tiết lộ cho Người chơi và Nhà cái, xác định xem cần thêm bài hay không. Đầu tiên, đánh giá bài của Người chơi, sau đó là bài của Nhà cái. Tham khảo bảng dưới đây để biết chi tiết:`,
    "d9": `Người Chơi Quy tắc kéo bài`,
    "d10": `Tổng Điểm của Hai Lá bài Người chơi`,
    "d11": `(Người chơi)`,
    "d12": `Phải kéo bài`,
    "d13": `Không được kéo bài`,
    "d14": `Thắng Tự nhiên, Trận Đấu Đã Quyết`,
    "d15": `Nhà Cái Quy tắc kéo bài`,
    "d16": `Tổng Điểm Hai Lá Bài của Nhà cái`,
    "d18": `(Nhà cái)`,
    "d19": `Phải kéo bài`,
    "d20": `Nếu Người chơi rút thêm lá bài thứ 3 có giá trị 8 điểm, Nhà cái không được rút bài.`,
    "d21": `Nếu Người chơi rút thêm lá bài thứ 3 có giá trị 0, 1, 8 hoặc 9 điểm, Nhà cái không được rút bài.`,
    "d22": `Nếu Người chơi rút thêm lá bài thứ 3 có giá trị 0, 1, 2, 3, 8 hoặc 9, Nhà cái không được rút bài.`,
    "d23": `Nếu Người chơi rút thêm lá bài thứ 3 có giá trị 6 hoặc 7, Nhà cái phải rút bài.`,
    "d24": `3.Tính Điểm`,
    "d25": `Điểm từ A đến 9 được tính dựa trên giá trị bài, trong đó A tính là 1 điểm.`,
    "d26": `10, J, Q, hoặc K đều có giá trị 0 điểm.`,
    "d27": `Ví dụ:`,
    "d28": `4.Cửa cược`,
    "d29": `4.1. Nhà cái & Người chơi & Hòa:`,
    "d30": `Nếu điểm của Nhà cái cao hơn Người chơi, những người đặt cược vào "Nhà cái" sẽ thắng`,
    "d31": `Nhà cái:`,
    "d32": `Người chơi:`,
    "d33": `Nếu điểm của Người chơi cao hơn Nhà cái, những người cá cược vào "Người chơi" thắng;`,
    "d34": `Nếu điểm của Người chơi và Nhà cái bằng nhau, những người cá cược vào "Hòa" thắng.`,
    "d35": `4.2. Nhà cái đôi & Người chơi đôi:`,
    "d36": `Trong trò chơi hiện tại, nếu hai lá bài đầu tiên của Nhà cái hoặc Người chơi tạo thành một cặp (cùng số hoặc chữ cái, không quan trọng chất của lá bài);`,
    "d37": `Nhà cái/Người chơi:`,
    "d38": `2 lá bài đầu của Nhà Cái là đôi, gọi là Nhà Cái đôi. 2 lá bài đầu của Người Chơi là đôi, gọi là Người Chơi đôi`,
    "d39": `Khi xuất hiện đôi, cược "Nhà Cái đôi" hoặc "Người Chơi đôi" là được trả thưởng với tỷ lệ tương ứng.`,
    "d40": `4.3. Đôi bất kỳ:`,
    "d41": `Là 2 lá bài đầu của Nhà Cái hoặc Người Chơi là đôi (Chi tiết xem Nhà Cái đôi & Người Chơi đôi)`,
    "d42": `Bất kể là Nhà cái đôi hay Người chơi đôi, những người đặt cược vào "Đôi bất kỳ" thắng.`,
    "d43": `4.4. Đôi Hoàn Hảo:`,
    "d44": `Áp dụng cho hai lá bài đầu tiên của Nhà Cái hoặc Người Chơi tạo thành một cặp (xem Đôi Nhà Cái & Đôi Người Chơi) cùng chất;`,
    "d45": `Dù Đôi Hoàn Hảo xuất hiện cho Nhà Cái hay Người Chơi, người đặt cược vào "Đôi Hoàn Hảo" thắng.`,
    "d46": `4.5. Super đôi:`,
    "d47": `Trong số 4 lá bài đầu tiên, nếu có hai hoặc nhiều lá bài cùng giá trị mặt (không kể chất).`,
    "d48": `4.6. Rồng 7:`,
    "d49": `Nhà Cái thắng với ba lá bài tổng cộng 7 điểm.`,
    "d50": `4.7. Gấu Trúc 8:`,
    "d51": `Người Chơi thắng với ba lá bài tổng cộng 8 điểm.`,
    "d52": `4.8. Hổ Lớn & Hổ Nhỏ:`,
    "d53": `Nhà Cái thắng với ba lá bài tổng cộng 6 điểm, gọi là Hổ Lớn;`,
    "d54": `Nhà Cái thắng với ba lá bài tổng cộng 6 điểm, gọi là Hổ Nhỏ.`,
    "d55": `4.9. Hổ Hòa:`,
    "d56": `Nhà Cái và Người Chơi hòa với 6 điểm mỗi người.`,
    "d57": `4.10.Hổ đôi:`,
    "d58": `Dựa vào kết quả của 4 lá bài đầu tiên của nhà cái và người chơi, bạn sẽ có tỷ lệ cược khác nhau nếu một trong 3 kết quả sau được đáp ứng:`,
    "d59": `Cả đôi nhà cái và đôi người chơi là đôi mặt giống nhau;`,
    "d60": `Cả đôi nhà cái và đôi người chơi;`,
    "d61": `Hoặc đôi nhà cái hoặc đôi người chơi;`,
    "d62": `Hòa không hoàn lại cược.`,
    "d63": `4.11. Nhà cái bài chuẩn & Người chơi bài chuẩn`,
    "d64": `Nếu hai lá bài đầu tiên của Nhà Cái tổng cộng 8 hoặc 9 điểm và Nhà Cái thắng, những người đặt cược vào "Nhà cái bài chuẩn" sẽ thắng;`,
    "d65": `Nếu hai lá bài đầu tiên của Người Chơi tổng cộng 8 hoặc 9 điểm và Người Chơi thắng, những người đặt cược vào "Người chơi bài chuẩn" sẽ thắng.`,
    "d66": `4.12. Bài chuẩn:`,
    "d67": `Nhà Cái hoặc Người Chơi thắng nếu hai lá bài đầu tiên của họ tổng cộng 8 hoặc 9 điểm.`,
    "d68": `4.13. Rồng Hổ Hoà:`,
    "d69": `Nhà Cái thắng cược Rồng nếu giá trị điểm của lá bài đầu tiên của Nhà Cái lớn hơn lá bài đầu tiên của Người Chơi (với 10, J, Q và K có giá trị lần lượt là 10, 11, 12 và 13);`,
    "d70": `Người Chơi thắng cược Hổ nếu giá trị điểm của lá bài đầu tiên của Người Chơi lớn hơn lá bài đầu tiên của Nhà Cái (với 10, J, Q và K có giá trị lần lượt là 10, 11, 12 và 13);`,
    "d71": `Nếu giá trị điểm của các lá bài đầu tiên của cả Nhà Cái và Người Chơi giống nhau (với 10, J, Q và K có giá trị lần lượt là 10, 11, 12 và 13), đó là Hòa Rồng Hổ.`,
    "d72": `4.14.Super 6:`,
    "d73": `Các cược có thể được đặt trong cả hai chế độ Có Hoa Hồng và Không Hoa Hồng;`,
    "d74": `Nếu Nhà Cái không rút bài, có tổng điểm kết thúc là 6 và giá trị điểm này lớn hơn của Người Chơi, thì tiền thưởng là 1:12;`,
    "d75": `Nếu Nhà Cái rút bài, có tổng điểm kết thúc là 6 và giá trị điểm này lớn hơn của Người Chơi, thì tiền thưởng là 1:20.`,
    "d76": `4.15. Nhà cái Long Bảo & Người chơi Long Bảo :`,
    "d77": `Nếu Nhà Cái thắng bằng bài chuẩn, Nhà cái Long Bảo sẽ thắng;`,
    "d78": `Nếu Người Chơi thắng bằng bài chuẩn, Người chơi Long Bảo sẽ thắng;`,
    "d79": `Nếu không phải là bài chuẩn của Nhà cái, điểm của Nhà cái - điểm của Người chơi = N, nếu N = 4~9,  Nhà cái Long Bảo sẽ thắng;`,
    "d80": `Nếu không phải là bài chuẩn của Người chơi, điểm của Người chơi - điểm của Nhà cái = N, nếu N = 4~9 Người chơi Long Bảo sẽ thắng;`,
    "d81": `Định nghĩa bài chuẩn: Kết quả của hai lá bài đầu tiên là 8 hoặc 9 điểm;`,
    "d82": `Định nghĩa bài không chuẩn: Tất cả các tình huống ngoài bài chuẩn, xem chi tiết trong bảng tỷ lệ cược.`,
    "d83": `4.16. Super Hòa:`,
    "d84": `Trong trường hợp hòa giữa Nhà Cái và Người Chơi, điểm hòa bao gồm "Hòa 0" đến "Hòa 9", tạo thành tổng cộng mười điểm Siêu hòa;`,
    "d85": `Ví dụ: Trong trò chơi nếu kết quả của các lá bài của Nhà Cái và Người Chơi đều là 0, những người đặt cược vào "Super hòa 0" sẽ thắng;`,
    "d86": `5. Bảng tỷ lệ`,
    "d87": `Trong Baccarat, cả Baccarat tiêu chuẩn và Baccarat không Hoa Hồng đều được cung cấp cách chơi. Luật chơi giống nhau cho cả hai, nhưng có sự khác biệt trong phương thức thanh toán cho việc đặt cược vào Nhà Cái:`,
    "d88": `Baccarat`,
    "d89": `Mục đặt cược`,
    "d90": `Tỷ lệ cược`,
    "d91": `Người Chơi`,
    "d92": `1:1 (Trả lại số tiền cược trong trường hợp hòa)`,
    "d93": `Nhà Cái`,
    "d94": `Hoa Hồng 1:0.95 (Trả lại số tiền cược trong trường hợp hòa)`,
    "d95": `Không Hoa Hồng 1:1 (Trả một nửa nếu Nhà Cái thắng với 6 điểm; trả lại số tiền cược trong trường hợp hòa)`,
    "d96": `Hòa`,
    "d97": `Người chơi đôi`,
    "d98": `Nhà cái đôi`,
    "d99": `Super đôi`,
    "d100": `Đôi hoàn hảo`,
    "d101": `Đôi bất kỳ`,
    "d102": `Rồng 7`,
    "d103": `Gấu Trúc 8`,
    "d104": `Hổ Lớn`,
    "d105": `Hổ Nhỏ`,
    "d106": `Hổ Hòa`,
    "d107": `Hổ đôi`,
    "d108": `1:100 Cả đôi nhà cái và đôi người chơi là đôi mặt giống nhau`,
    "d109": `1:20 <br> Cả đôi nhà cái và đôi người chơi;`,
    "d110": `1:4 <br> Hoặc đôi nhà cái hoặc đôi người chơi`,
    "d111": `Nhà cái bài chuẩn & Người chơi bài chuẩn`,
    "d112": `Bài chuẩn`,
    "d113": `Rồng & Hổ`,
    "d114": `Trả lại một nửa tiền cược trong trường hợp Rồng Hổ Hòa`,
    "d115": `Rồng Hổ Hòa`,
    "d116": `Super 6`,
    "d117": `1:12 <br> Nếu Nhà Cái thắng với hai lá bài có tổng điểm là 6`,
    "d118": `1:20 <br> Nếu Nhà Cái thắng với ba lá bài có tổng điểm là 6`,
    "d119": `Long Bảo `,
    "d120": `Hòa chuẩn, trả lại tiền cược`,
    "d121": `trả lại tiền cược`,
    "d122": `Thắng bằng bài chuẩn`,
    "d123": `Thắng 4 điểm bằng bài không chuẩn.`,
    "d124": `Thắng 5 điểm bằng bài không chuẩn`,
    "d125": `Thắng 6 điểm bằng bài không chuẩn`,
    "d126": `Thắng 7 điểm bằng bài không chuẩn`,
    "d127": `Thắng 8 điểm bằng bài không chuẩn`,
    "d128": `Thắng 9 điểm bằng bài không chuẩn`,
    "d129": `Super hòa`,
    "d130": `Super hòa (0)`,
    "d131": `Super hòa (1)`,
    "d132": `Super hòa (2)`,
    "d133": `Super hòa (3)`,
    "d134": `Super hòa (4)`,
    "d135": `Super hòa (5)`,
    "d136": `Super hòa (6)`,
    "d137": `Super hòa (7)`,
    "d138": `Super hòa (8)`,
    "d139": `Super hòa (9)`,
    "d140": `6. Tính năng Trò Chơi`,
    "d141": `Sử dụng 8 bộ bài phổ biến nhất (Không bao gồm Joker, tổng cộng 416 lá bài) cho trò chơi. Với các quy tắc cá cược độc đáo, giống như trong cuộc sống, bất kể hoàn cảnh hiện tại ra sao, miễn là bạn đầu tư, luôn có cơ hội để lật ngược tình thế.`,
    "d142": `7. Những điều cần chú ý`,
    "d143": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d144": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d145": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d146": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d147": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d148": `Quy tắc trò chơi`,
    "d149": `Quy tắc kéo bài`,
    "d150": `Tính Điểm`,
    "d151": `Cửa cược`,
    "d152": `Bảng tỷ lệ`,
    "d153": `Tính năng Trò Chơi`,
    "d154": `Những điều cần chú ý`
  },
  "DragonTiger": {
    "d1": `Rồng Hổ`,
    "d2": `1.Quy tắc trò chơi`,
    "d3": `Trò chơi sử dụng 8 bộ bài (không bao gồm Joker), tổng cộng 416 lá`,
    "d4": `Chỉ chia hai bên, đầu tiên cho Rồng, sau đó cho Hổ, mỗi bên nhận 1 lá, không cần rút thêm lá`,
    "d5": `Sau khi chia, so sánh điểm tổng của mỗi bên để xác định kết quả`,
    "d6": `2.Tính Điểm`,
    "d7": `Giá trị mặt của lá A là 1;`,
    "d8": `Giá trị mặt của lá 2-10 tương ứng với số trên lá bài, tức là từ 2-10;`,
    "d9": `J, Q, K có giá trị lần lượt là 11, 12, 13`,
    "d10": `3.Cửa cược`,
    "d11": `Trong so sánh giữa Rồng và Hổ, bên lớn hơn thắng; nếu Rồng lớn hơn, Rồng thắng; nếu Hổ lớn hơn, Hổ thắng`,
    "d12": `Nếu Rồng và Hổ có tổng điểm bằng nhau, đó là hòa`,
    "d13": `4.Bảng tỷ lệ`,
    "d14": `Mục đặt cược`,
    "d15": `Tỷ lệ cược`,
    "d16": `Rồng`,
    "d18": `1:1`,
    "d19": `Trả lại một nửa tiền cược nếu hòa.`,
    "d20": `Hổ`,
    "d21": `1:1`,
    "d22": `Trả lại một nửa tiền cược nếu hòa.`,
    "d23": `Hòa`,
    "d24": `1:8`,
    "d25": `5.Tính năng Trò Chơi`,
    "d26": `So với Baccarat, không cần rút thêm lá, chỉ một lá là đủ. Luật chơi đơn giản, dễ hiểu, phù hợp với mọi lứa tuổi để trải nghiệm niềm vui của trò chơi một cách nhanh chóng.`,
    "d27": `6.Những điều cần chú ý`,
    "d28": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d29": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d30": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d31": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d32": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d33": `Quy tắc trò chơi`,
    "d34": `Tính Điểm`,
    "d35": `Cửa cược`,
    "d36": `Bảng tỷ lệ`,
    "d37": `Tính năng Trò Chơi`,
    "d38": `Những điều cần chú ý`
  },
  "Bulls": {
    "d1": `Ngưu Ngưu`,
    "d2": `1.Quy tắc trò chơi`,
    "d3": `Một bộ bài 52 lá (không bao gồm Joker);`,
    "d4": `Thứ tự phát bài: Lần lượt là Nhà Cái → Người Chơi 1→ Người Chơi 2→ Người Chơi 3, nhưng cần dùng lá bài đầu quyết định xem phát bài cho ai trước;`,
    "d5": `Ba người chơi cạnh tranh với Nhà cái, đầu tiên so sánh loại bài. Nếu loại bài giống nhau, sẽ so sánh giá trị của lá bài cao nhất. Nếu giá trị mặt bài giống nhau, sẽ so sánh theo chất.`,
    "d6": `2.Tính Điểm`,
    "d7": `Át được tính là 1; các lá bài 10, J, Q, K được tính là 10 điểm; các lá 2-9 tính theo giá trị trên lá bài`,
    "d8": `Mỗi nhà có 5 lá bài,Nếu tổng của ba lá bài trong tay là bội số của 10, được coi là 'có Bull'; nếu không, là 'không bull';`,
    "d9": `Trong trò chơi bài, nếu có Bull, chữ số hàng đơn vị của tổng hai lá còn lại sẽ xác định giá trị. Nếu chữ số hàng đơn vị là 1-9, nó tương ứng với "Bull" của nó. Nếu là 0, gọi là "BullBull".`,
    "d10": `Nếu cả năm lá bài là J/Q/K, tay bài được phân loại là "Năm Tây".`,
    "d11": `3.Cửa cược`,
    "d12": `Trong trò chơi "BullBull", có Nhà cái, Người chơi 1, Người chơi 2 và Người chơi 3 với bài của họ`,
    "d13": `Bố cục cược được chia thành ba phần: Người chơi 1, Người chơi 2 và Người chơi 3. Mỗi phần bao gồm các tùy chọn cho Nhà Cái Thường, Nhà Cái Gấp bội, Nhà Cái Siêu bội, Người Chơi Thường, Người Chơi Gấp bội và Người Chơi Siêu bội;`,
    "d14": `Sau khi lật bài, so sánh bài của Nhà cái và ba Người chơi. Nếu bài của Nhà cái cao hơn, cược vào Nhà cái sẽ thắng. Nếu bài của Người chơi cao hơn, cược vào Người chơi sẽ thắng`,
    "d15": `Kiểu bài: Năm Tây > BullBull > Bull 9 > Bull 8 > Bull 7 > Bull 6 > Bull 5 > Bull 4 > Bull 3 > Bull 2 > Bull 1 > Không Bull.`,
    "d16": `Xếp hạng: K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2 > A`,
    "d18": `Chất: Bích > Cơ > Nhép > Rô;`,
    "d19": `Cược gấp đôi, thắng thua cũng gấp đôi. Cược nhiều lần, thì thắng thua cũng nhiều lần`,
    "d20": `4.Bảng tỷ lệ`,
    "d21": `Tỷ lệ cược cụ thể phụ thuộc vào kiểu bài của người thắng. Cược gấp đôi, thắng thua cũng gấp đôi; cược nhiều lần, thắng thua cũng nhiều lần.`,
    "d22": `Tỷ Lệ Thắng của Người Chơi`,
    "d23": `Kiểu bài bên thắng`,
    "d24": `Thường`,
    "d25": `Gấp bội`,
    "d26": `Năm Tây `,
    "d27": `BullBull`,
    "d28": `Bull`,
    "d29": `Không Bull`,
    "d30": `Tỷ Lệ Thua của Người Chơi`,
    "d31": `Kiểu bài bên thắng`,
    "d32": `Thường`,
    "d33": `Gấp bội`,
    "d34": `Năm Tây `,
    "d35": `BullBull`,
    "d36": `Bull7-Bull9`,
    "d37": `Không Bull - Bull6`,
    "d38": `Ví dụ 1 về đặt cược và thanh toán "gấp đôi":`,
    "d39": `Đối tượng đặt cược`,
    "d40": `Một Người chơi`,
    "d41": `Lối chơi`,
    "d42": `Người Chơi Gấp bội`,
    "d43": `Số tiền đặt cược`,
    "d44": `100, thực tế bị trừ 500, sẽ hiển thị trong sổ sách là "100 (trừ trước 400)"`,
    "d45": `Kết quả so sánh`,
    "d46": `Bài của Nhà cái: Bull 8 | Bài của Nhà 1: Bull 5`,
    "d47": `Đã thanh toán`,
    "d48": `Hoàn lại 300 (tay thắng Bull 8, tương ứng với tiền thua của người chơi là 2, người chơi thua 100 x 2 = 200)`,
    "d49": `Ví dụ 2 về đặt cược và thanh toán "gấp đôi":`,
    "d50": `Đối tượng đặt cược`,
    "d51": `Một Người chơi`,
    "d52": `Lối chơi`,
    "d53": `Nhà Cái Gấp bội`,
    "d54": `Số tiền đặt cược`,
    "d55": `100, thực tế bị trừ 500, sẽ hiển thị trong sổ sách là "100 (trừ trước 400)"`,
    "d56": `Kết quả so sánh`,
    "d57": `Bài của Nhà cái: Bull 8 | Bài của Nhà 1: Bull 5`,
    "d58": `Đã thanh toán`,
    "d59": `690 (Hoàn lại 500 + 100 x tiền thắng của người chơi là 1.90 cho tay thắng Bull 8 = 690)`,
    "d60": `5.Tính năng Trò Chơi`,
    "d61": `Một trò chơi bài phổ biến ở các khu vực Hồ Quảng. Người chơi có thể tự do đặt cược vào Nhà cái hoặc Người chơi. Tính năng gấp đôi sẽ mang lại các tỷ lệ khác nhau dựa trên Tay bài Chiến thắng, đem lại trải nghiệm thú vị và kích thích!`,
    "d62": `6.Những điều cần chú ý`,
    "d63": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d64": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d65": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d66": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d67": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d68": `Quy tắc trò chơi`,
    "d69": `Tính điểm`,
    "d70": `Cửa cược`,
    "d71": `Bảng tỷ lệ`,
    "d72": `Tính năng Trò Chơi`,
    "d73": `Những điều cần chú ý`,
    "d74": `Siêu bội`
  },
  "SanGong": {
    "d1": `Bài 3 tây`,
    "d2": `1.Quy tắc trò chơi`,
    "d3": `Một bộ bài gồm 52 lá (không bao gồm lá Joker);`,
    "d4": `Thứ tự phát bài: Lần lượt là Nhà Cái → Người Chơi 1→ Người Chơi 2→ Người Chơi 3, nhưng cần dùng lá bài đầu quyết định xem phát bài cho ai trước;`,
    "d5": `Tay bài của ba Người chơi sẽ được so sánh với tay bài của Nhà cái. Nếu Người chơi có tay bài lớn hơn, Người chơi sẽ thắng; nếu Nhà cái có tay bài lớn hơn, Người chơi sẽ thua.`,
    "d6": `2.So Kiểu bài`,
    "d7": `Trong trò chơi, các lá bài hình J, Q, K được coi là lá bài chung.`,
    "d8": `Luật chơi`,
    "d9": `Hướng dẫn (Xếp hạng tay bài từ cao nhất đến thấp nhất, liệt kê từ trên xuống dưới)`,
    "d10": `Bài 3 tây`,
    "d11": `J, Q, K là 'Hình'; một tay gồm cả ba lá 'Hình' được gọi là 'Ba Tây'.`,
    "d12": `Hai Tây 9`,
    "d13": `Hai lá Hình và lá còn lại có 9 điểm`,
    "d14": `Một Tây 9`,
    "d15": `Một lá Hình, tổng của hai lá còn lại bằng 9, với chữ số hàng đơn vị là 9`,
    "d16": `9 Điểm`,
    "d18": `Tổng điểm của 3 lá bài là 9, với chữ số hàng đơn vị là 9`,
    "d19": `Hai Tây 8`,
    "d20": `Hai lá Hình và lá còn lại có 8 điểm`,
    "d21": `Một Tây 8`,
    "d22": `Một lá Hình, tổng của hai lá còn lại bằng 8, với chữ số hàng đơn vị là 8`,
    "d23": `8 Điểm`,
    "d24": `Tổng điểm của 3 lá bài là 8, với chữ số hàng đơn vị là 8`,
    "d25": `Hai Tây 7`,
    "d26": `Hai lá Hình và lá còn lại có 7 điểm`,
    "d27": `Một Tây 7`,
    "d28": `Một lá Hình, tổng của hai lá còn lại bằng 7, với chữ số hàng đơn vị là 7`,
    "d29": `7 Điểm`,
    "d30": `Tổng điểm của 3 lá bài là 7, với chữ số hàng đơn vị là 7`,
    "d31": `Hai Tây 6`,
    "d32": `Hai lá Hình và lá còn lại có 6 điểm`,
    "d33": `Một Tây 6`,
    "d34": `Một lá Hình, tổng của hai lá còn lại bằng 6, với chữ số hàng đơn vị là 6`,
    "d35": `6 Điểm`,
    "d36": `Tổng điểm của 3 lá bài là 6, với chữ số hàng đơn vị là 6`,
    "d37": `Hai Tây 5`,
    "d38": `Hai lá Hình và lá còn lại có 5 điểm`,
    "d39": `Một Tây 5`,
    "d40": `Một lá Hình, tổng của hai lá còn lại bằng 5, với chữ số hàng đơn vị là 5`,
    "d41": `5 Điểm`,
    "d42": `Tổng điểm của 3 lá bài là 5, với chữ số hàng đơn vị là 5`,
    "d43": `Hai Tây 4`,
    "d44": `Hai lá Hình và lá còn lại có 4 điểm`,
    "d45": `Một Tây 4`,
    "d46": `Một lá Hình, tổng của hai lá còn lại bằng 4, với chữ số hàng đơn vị là 4`,
    "d47": `4 Điểm`,
    "d48": `Tổng điểm của 3 lá bài là 4, với chữ số hàng đơn vị là 4`,
    "d49": `Hai Tây 3`,
    "d50": `Hai lá Hình và lá còn lại có 3 điểm`,
    "d51": `Một Tây 3`,
    "d52": `Một lá Hình, tổng của hai lá còn lại bằng 3, với chữ số hàng đơn vị là 3`,
    "d53": `3 Điểm`,
    "d54": `Tổng điểm của 3 lá bài là 3, với chữ số hàng đơn vị là 3`,
    "d55": `Hai Tây 2`,
    "d56": `Hai lá Hình và lá còn lại có 2 điểm`,
    "d57": `Một Tây 2`,
    "d58": `Một lá Hình, tổng của hai lá còn lại bằng 2, với chữ số hàng đơn vị là 2`,
    "d59": `2 Điểm`,
    "d60": `Tổng điểm của 3 lá bài là 2, với chữ số hàng đơn vị là 2`,
    "d61": `Hai Tây 1`,
    "d62": `Hai lá Hình và lá còn lại có 1 điểm`,
    "d63": `Một Tây 1`,
    "d64": `Một lá Hình, tổng của hai lá còn lại bằng 1, với chữ số hàng đơn vị là 1`,
    "d65": `1 Điểm`,
    "d66": `Tổng điểm của 3 lá bài là 1, với chữ số hàng đơn vị là 1`,
    "d67": `Hai Tây 0`,
    "d68": `Hai lá Hình và lá còn lại có 0 điểm`,
    "d69": `Một Tây 0`,
    "d70": `Một lá Hình, tổng của hai lá còn lại bằng 0, với chữ số hàng đơn vị là 0`,
    "d71": `0 Điểm`,
    "d72": `Tổng điểm của 3 lá bài là 0, với chữ số hàng đơn vị là 0`,

    "d84": `3.Cửa cược`,
    "三公中有庄，闲1，闲2，闲3四家牌；": `Trong trò chơi "Bài 3 tây", có Nhà cái, Người chơi 1, Người chơi 2 và Người chơi 3 với bài trên tay của họ;`,
    "下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；": `Bố cục đặt cược được chia thành ba phần: Người chơi 1, Người chơi 2 và Người chơi 3. Mỗi phần bao gồm các tùy chọn cho Nhà cái Thường, Nhà cái Gấp bội, Nhà cái Siêu bội, Người chơi Thường, Người chơi Gấp bội và Người chơi Siêu bội;`,
    "开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；": `Sau khi lật bài, tay bài của Nhà cái và ba Người chơi sẽ được so sánh. Nếu tay bài của Nhà cái lớn hơn, các cược vào Nhà cái sẽ thắng. Nếu tay bài của Người chơi lớn hơn, các cược vào Người chơi sẽ thắng;`,
    "大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；": `Xếp hạng: K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2 > A`,
    "花色：黑桃＞红心＞梅花＞方块；": `Chất: Bích > Cơ > Nhép > Rô;`,
    "押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。": `Tiền thắng thua cược chẵn là ngang nhau. Tiền thắng thua cược gấp đôi là nhân lên. Tiền thắng thua cược siêu là nhân lên nhiều hơn.`,
    "d91": `4.Bảng tỷ lệ`,
    "d92": `Tỷ lệ được chia thành tỷ lệ thắng/thua của nhà cái-người chơi, và tỷ lệ cho loại tay bài đặc biệt.`,
    "d93": `Kết quả trò chơi`,
    "d94": `Tỷ lệ cược`,
    "d95": `Người chơi (thắng)`,
    "d96": `1:1`,
    "d97": `Người chơi (thua)`,
    "d98": `1:1`,
    "d99": `Hòa`,
    "d100": `Bài 3 tây`,
    "d101": `Loại tay bài`,
    "d102": `Tỷ lệ cược`,
    "d108": `Nếu một tay đồng thời đạt được nhiều kết quả, kết quả có tỷ lệ cao hơn sẽ được áp dụng;`,
    "d109": `Ví dụ, ♠️2 ♠️3 ♠️4 có thể tạo thành thùng phá sảnh và sảnh, nhưng vì thùng phá sảnh có tỷ lệ cược cao hơn, nên tiền thưởng sẽ dựa trên thùng phá sảnh.`,
    "d110": `Không hoa hồng trên các cược, nhưng có tỷ lệ đặc biệt trong hai tình huống sau:`,
    "d111": `Đối với cược thắng, nếu người chơi thắng với bất kỳ tổ hợp nào có tổng cộng 6 điểm, tỷ lệ trả thưởng là 1 đến 0.5;`,
    "d112": `Đối với cược thua, nếu nhà cái thắng với bất kỳ tổ hợp nào có tổng cộng 6 điểm, tỷ lệ trả thưởng là 1 đến 0.5.`,
    "d113": `5.Tính năng Trò Chơi`,
    "d114": `Bài 3 tây là trò chơi dân gian truyền thống với cách cược đơn giản, không khí sôi động, không cần các thao tác hay tính toán phức tạp. Nó dựa vào may mắn và khá phổ biến trong các thể loại trò chơi mới nổi.`,
    "d115": `6.Những điều cần chú ý`,
    "d116": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d117": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d118": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d119": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d120": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d121": `Quy tắc trò chơi`,
    "d122": `So Kiểu bài`,
    "d123": `Đôi bài hoặc cao hơn`,
    "d124": `Cửa cược`,
    "d125": `Bảng tỷ lệ`,
    "d126": `Tính năng Trò Chơi`,
    "d127": `Những điều cần chú ý`,
    "玩家赢赔率": `Tỷ Lệ Thắng của Người Chơi`,
    "玩家输赔率": `Tỷ Lệ Thua của Người Chơi`,
    "胜方牌型": `Kiểu bài bên thắng`,
    "平倍": `Thường`,
    "翻倍": `Gấp bội`,
    "超倍": `Siêu bội`,
    "三条": `Sám`,
    "3条3": `Sám 3 lá`,
    "点": `Điểm`
  },
  "GoldenFlower": {
    "龙凤炸金花": `L/P nổ kim hoa`,
    "游戏规则": `Quy tắc trò chơi`,
    "使用一副牌游戏（去大小王）进行游戏；": `Sử dụng một bộ bài tiêu chuẩn (không bao gồm Joker) để chơi trò chơi;`,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小（不看花色），决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。": `So kiểu bài trước, nếu kiểu bài như nhau thì so sánh điểm số theo thứ tự từ lớn đến nhỏ (Không tính chất bài) để quyết định thắng thua. Nếu điểm số 3 lá bài của long, phượng bằng nhau thì hòa, hoàn lại tất cả tiền cược long, phượng của người chơi trong ván này.`,
    "玩法判定": `Cửa cược`,
    "牌型": `Kiểu bài`,
    "豹子杀手：": `Sát thủ bão:`,
    "三张非同花的2，3，5；": `Ba lá 2, 3, 5 không đồng chất;`,
    "豹子：": `Bão:`,
    "三张点数相同的牌；": `Ba lá cùng giá trị;`,
    "同花顺：": `Thùng Phá sảnh:`,
    "花色相同的顺子牌；": `Các lá bài liên tiếp cùng chất;`,
    "同花：": `Thùng:`,
    "花色相同的任意三张牌；": `Ba lá bất kỳ cùng chất;`,
    "顺子：": `Sảnh:`,
    "三张连续的牌；": `Ba lá liên tiếp;`,
    "对子：": `Đôi:`,
    "两张点数相同的牌；": `Hai lá cùng giá trị;`,
    "散牌：": `Bài Lẻ:`,
    "无法组成任何类型的牌；": `Không thể tạo thành loại tay bài nào;`,
    "牌型大小": `So Kiểu bài`,
    "豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Bão > Thùng Phá sảnh > Thùng > Sảnh > Đôi > Bài Lẻ;`,
    "豹子杀手＞豹子；": `Sát thủ bão > Bão;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `Khi có cả tay Sát thủ bão và Bão, tay Sát thủ bão mạnh hơn Bão;`,
    "散牌＞豹子杀手；": `Bài Lẻ > Sát thủ bão;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `Nếu không có tay Bão, Sát thủ bão là tay Bài Lẻ thấp nhất.`,
    "对子大小": `Đôi lớn nhỏ`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `Nếu cả hai người chơi đều có tay Đôi, so sánh thứ hạng của các Đôi; Đôi A > Đôi K > Đôi Q > Đôi J > Đôi 10 > Đôi 9 > Đôi 8 > Đôi 7 > Đôi 6 > Đôi 5 > Đôi 4 > Đôi 3 > Đôi 2;`,
    "两对对子的点数相同，再比单牌点数大小。": `Nếu thứ hạng của hai đôi giống nhau, so sánh thứ hạng của từng lá bài.`,
    "豹子、同花、散牌牌型": `Bão, Thùng, Tay Bài Lẻ`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。": `So sánh giá trị điểm của lá bài cao nhất; nếu giống nhau, so sánh giá trị điểm của lá bài cao thứ hai, và cứ tiếp tục như vậy; Xét về giá trị điểm: A > K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2. Các chất bài không được xếp hạng.`,
    "同花顺，顺子": `5. Thùng Phá Sảnh, Sảnh`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `So điểm theo thứ tự: AKQ>KQJ>......>432>32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Lưu ý: Chỉ trong Thùng Phá Sảnh và Sảnh, A mới được xem là quân bài lớn nhất hoặc nhỏ nhất; trong các loại bài khác, A là quân bài đơn lớn nhất`,
    "赔率表": `Bảng tỷ lệ`,
    "投注项目": `Mục đặt cược`,
    "规则": `Quy tắc`,
    "赔率": `Tỷ lệ cược`,
    "龙": `Long`,
    "龙胜出": `Long Thắng`,
    "凤": `Phượng`,
    "凤胜出": `Phượng Thắng`,
    "对8以上": `Đôi 8 Trở Lên`,
    "龙或凤以对8以上的牌型胜出": `Long hoặc Phượng thắng với đôi 8 trở lên trong tay`,
    "顺子": `Sảnh`,
    "龙或凤以顺子的牌型胜出": `Long hoặc Phượng thắng với Sảnh trong tay`,
    "同花": `Thùng`,
    "龙或凤以同花的牌型胜出": `Long hoặc Phượng thắng với Thùng trong tay`,
    "同花顺": `Thùng Phá sảnh`,
    "龙或凤以同花顺的牌型胜出": `Long hoặc Phượng thắng với Thùng Phá sảnh trong tay`,
    "豹子": `Bão`,
    "龙或凤以豹子的牌型胜出": `Long hoặc Phượng thắng với Bão trong tay`,
    "游戏特点": `Tính năng Trò Chơi`,
    "龙凤炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。": `Long Phượng bài ba lá, còn được gọi là "Thắng Ba Lá Bài", là một trò chơi bài dân gian phổ biến. Một đặc điểm nổi bật của trò chơi này, ngoài việc đặt cược vào "Long" và "Phượng" như thường lệ, là người chơi cũng có thể đặt cược vào tay bài chiến thắng. Ngoài ra, bất kỳ tay bài nào trên đôi tám đều được coi là tay bài chiến thắng. Lối chơi này trải dài từ đơn giản đến phức tạp, phục vụ cho người chơi ở mọi cấp độ.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "TexasHoldem": {
    "德州扑克": `Texas Poker`,
    "游戏规则": `Quy tắc trò chơi`,
    "采用1副牌（去大小王），共计52张；": `Sử dụng một bộ bài tiêu chuẩn (không bao gồm Joker) với tổng số 52 lá;`,
    "首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；": `Đầu tiên, chia năm lá bài chung, sau đó chia 2 lá cho người chơi và 2 lá cho nhà cái;`,
    "投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；": `Sau khi thời gian đặt cược kết thúc, người chia bài sẽ tiết lộ hai lá bài của người chơi và ba lá bài chung đầu tiên;`,
    "然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。": `Sau đó, trò chơi bước vào giai đoạn cượci, nơi người chơi phải quyết định có theo cược hay không. Nếu họ chọn theo, họ cần đặt cược gấp đôi số tiền cược ban đầu ở khu vực gọi. Nếu họ chọn không theo, họ sẽ mất vòng và mất cược ban đầu.`,
    "跟注环节结束后，荷官翻开后2张公共牌；": `Sau khi kết thúc giai đoạn cược, người chia bài sẽ tiết lộ hai lá bài chung cuối cùng.`,
    "玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。": `Người chơi sử dụng hai lá bài của họ và năm lá bài chung để tạo thành tay bài năm lá tốt nhất, sau đó so sánh với tay bài của nhà cái để xác định ai có tay bài cao hơn.`,
    "AA边注玩法": `Quy tắc Tiền thưởng`,
    "AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。": `Thưởng là một cược tùy chọn. Nó có thể được sử dụng để cược xem năm lá bài đầu tiên được chia (hai lá của người chơi và ba lá chung đầu tiên) có chứa một đôi Át hoặc tay bài tốt hơn không.`,
    "玩法判定": `Cửa cược`,
    "牌型": `So Kiểu bài`,
    "皇家同花顺": `Sảnh Chúa`,
    "皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Một Sảnh Chúa thắng bao gồm một chuỗi có Át, K, Q, J và 10 cùng chất. Trong trường hợp hòa, người chơi sẽ lấy lại số tiền cược ban đầu (cả tiền cược ban đầu và tiền cược gọi), và kết quả trò chơi là hòa.`,
    "同花顺": `Thùng phá sảnh`,
    "同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Một Thùng phá sảnh bao gồm năm lá có thứ hạng liên tiếp, tất cả cùng chất, chẳng hạn như 9, 8, 7, 6, 5 cơ. Khi so sánh hai Sảnh Đồng Chất, tay bài có lá cao hơn sẽ thắng. Trong trường hợp hòa, người chơi sẽ lấy lại tiền cược ban đầu (cả cược ban đầu và cược gọi), và kết quả trò chơi là hòa.`,
    "注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。": `Lưu ý: A cùng chất 2, 3, 4, 5 là Sảnh Đồng Chất thấp nhất; tuy nhiên, các chuỗi cùng chất J, Q, K, A, 2 hoặc Q, K, A, 2, 3 hoặc K, 1, 2, 3, 4 không được tính là Thùng phá sảnh`,
    "四条": `Tứ quý`,
    "四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。": `Tứ quý đề cập đến một tay bài có bốn lá cùng hạng, mỗi lá có chất khác nhau, cộng với một lá khác. Ví dụ, nếu bạn có mỗi lá Át của mỗi chất, đó là Tứ quý. Tứ quý cao hơn đánh bại Tứ quý thấp hơn.`,
    "葫芦": `Cù lũ`,
    "葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。": `Một Cù lũ bao gồm ba lá cùng hạng của bất kỳ chất nào, cộng với hai lá khác hạng, chẳng hạn như ba lá K và hai lá 6. Khi so sánh hai Cù lũ, tay bài có bộ ba lá có thứ hạng cao hơn sẽ thắng.`,
    "同花": `Thùng`,
    "同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Một Thùng bao gồm tất cả các lá trong tay cùng một chất, dù không theo thứ tự, chẳng hạn như năm lá chuồn. Khi so sánh hai Đồng Chất, người chiến thắng được xác định bởi lá bài cao nhất trong mỗi tay. Nếu các lá bài cao nhất giống nhau, so sánh lá bài cao thứ hai, và cứ tiếp tục như vậy cho đến khi xác định được người thắng. Trong trường hợp hòa, người chơi sẽ lấy lại tiền cược ban đầu (cả cược ban đầu và cược gọi), và kết quả trò chơi là hòa.`,
    "顺子": `Sảnh`,
    "顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Một Sảnh bao gồm năm lá bài liên tiếp mà không cùng chất, chẳng hạn như 9, 8, 7, 6, 5 không đồng chất. Khi so sánh hai Sảnh, tay bài có lá cao nhất sẽ thắng. Nếu hai Sảnh có cùng lá bài cao nhất, chất bài không ảnh hưởng đến kết quả; trong trường hợp hòa, người chơi sẽ lấy lại tiền cược ban đầu (cả cược ban đầu và cược gọi), và kết quả trò chơi là hòa.`,
    "注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。": `Lưu ý: A, 2, 3, 4, 5 là Sảnh thấp nhất có thể; J, Q, K, A, 2 hoặc Q, K, A, 2, 3 hoặc K, 1, 2, 3, 4 không được tính là Sảnh.`,
    "三条": `Sám`,
    "三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。": `Sám đề cập đến một tay bài có ba lá cùng hạng, mỗi lá có chất khác nhau, cùng với hai lá không phù hợp khác. Ví dụ, nếu người chơi có ba lá K, được gọi là Bộ ba. Bộ ba có thứ hạng cao hơn sẽ đánh bại Bộ ba có thứ hạng thấp hơn.`,
    "两对": `Hai đôi`,
    "两对以下": `Dưới hai đôi`,
    "两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Hai đôi đề cập đến một tay bài chứa hai lá cùng hạng và hai lá có hạng khác (cùng hạng nhưng khác với đôi đầu tiên), cùng một lá bài khác. Một tay với hai lá Át và hai lá K là ví dụ về Hai đôi. Khi so sánh hai tay Hai đôi, đôi có giá trị cao hơn trong mỗi tay sẽ được so sánh trước, và tay có đôi cao hơn sẽ thắng. Nếu đôi cao nhất giống nhau, so sánh các đôi thấp hơn. Nếu cả hai đôi giống nhau, kết quả được quyết định bởi lá bài còn lại. Trong trường hợp hòa, người chơi sẽ lấy lại tiền cược ban đầu (cả cược ban đầu và cược gọi), và kết quả trò chơi là hòa.`,
    "一对": `Đôi`,
    "对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Đôi đề cập đến một tay bài có hai lá cùng hạng (ví dụ, hai lá K), cùng với ba lá không phù hợp về hạng. Đôi có thứ hạng cao hơn sẽ thắng đôi có thứ hạng thấp hơn. Nếu hai đôi có thứ hạng giống nhau, ba lá còn lại sẽ được so sánh theo thứ tự giảm dần. Trong trường hợp hòa, người chơi sẽ lấy lại tiền cược ban đầu (cả cược ban đầu và cược gọi), và kết quả trò chơi là hòa.`,
    "高牌": `Mậu thầu`,
    "高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。": `Mậu thầu đề cập đến một tay bài không phù hợp với bất kỳ loại nào được đề cập trước đó. Nó không thuộc loại tay bài cụ thể nào.`,
    "牌型大小": `So Kiểu bài`,
    "皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌": `Sảnh Chúa > Thùng phá sảnh > Tứ quý > Cù lũ > Thùng > Sảnh > Sám > Hai đôi > Một đôi > Mậu thầu`,
    "庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。": `Tay bài cuối cùng của nhà cái phải là Đôi Bốn hoặc cao hơn để đủ điều kiện so sánh với tay bài của người chơi; nếu không, người chơi được coi là người thắng.`,
    "输赢判定和赔率表": `Xác định Thắng và Thua và Bảng Thanh Toán`,
    "底注、跟注玩法赔率（不含本金）如下：": `Tỷ lệ thanh toán cho cược ban đầu và cược gọi (không bao gồm vốn) như sau:`,
    "输赢": `Thắng và Thua`,
    "底注": `Cược gốc`,
    "跟注": `Theo cược`,
    "庄家没有资格(对4或以下),玩家胜": `Nhà cái không đủ điều kiện (với đôi bốn hoặc thấp hơn), người chơi thắng`,
    "赔付表": `Bảng Thanh Toán`,
    "返回本金": `Hoàn lại Vốn`,
    "庄家有资格(对4或以上),玩家胜": `Nhà cái đủ điều kiện (với đôi bốn hoặc cao hơn), người chơi thắng`,
    "庄家有资格(对4或以上),平局": `Nhà cái đủ điều kiện (với đôi bốn hoặc cao hơn), hòa`,
    "玩家不跟注(弃牌)": `Người chơi bỏ bài`,
    "输": `Thua`,
    "庄家有资格(对4或以上),庄家胜": `Nhà cái đủ điều kiện (với đôi bốn hoặc cao hơn), nhà cái thắng`,
    "底注玩法赔率（不含本金）如下：": `Cược gốc (không bao gồm vốn) như sau:`,
    "手牌": `Kiểu bài `,
    "赔率": `Tỷ lệ cược`,
    "AA边注玩法赔率（不含本金）如下：": `Tiền thưởng (không bao gồm vốn) như sau:`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "SicBo": {
    "骰宝": `SicBo`,
    "游戏规则": `Quy tắc trò chơi`,
    "荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳；": `Người chia bài kích hoạt công tắc, khiến máy rung ba lần trở lên và ba viên xúc xắc sáu mặt trong ly lắc trong suốt sẽ nảy lên dưới tác động của độ rung;`,
    "当停止震动，骰子落下时，取其上面显现的点数计数；": `Khi độ rung dừng lại và xúc xắc ổn định, các con số hiển thị trên mặt trên của xúc xắc được đếm;`,
    "和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；": `Các cược khớp với các con số, kết hợp hoặc tổng của các con số trên ba viên xúc xắc sẽ thắng;`,
    "如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。": `Sau khi độ rung dừng lại, nếu bất kỳ viên xúc xắc nào trong ba viên không hoàn toàn tiếp xúc với đáy ly lắc, chồng chéo hoặc số lần rung ít hơn ba, độ rung sẽ được kích hoạt lại để có kết quả mới.`,
    "玩法判定": `Cửa cược`,
    "大/小": `Lớn/Nhỏ`,
    "三颗骰子总点数11-17为大，4-10为小；": `Tổng cộng từ 11-17 điểm trên ba viên xúc xắc được coi là 'Lớn', và 4-10 điểm được coi là 'Nhỏ';`,
    "出现围骰，庄家通吃，大/小均输。": `Nếu có ba viên xúc xắc giống nhau, nhà cái thắng tất cả và các cược 'Lớn' và 'Nhỏ' đều thua.`,
    "单/双": `Lẻ/Chẵn`,
    "三颗骰子和值，分为单双两种情况；": `Tổng của ba viên xúc xắc có thể được phân loại thành hai loại: lẻ hoặc chẵn;`,
    "总点数为5，7，9，11，13，15，17为单；": `Tổng điểm 5, 7, 9, 11, 13, 15 và 17 được coi là Lẻ;`,
    "总点数为4，6，8，10，12，14，16为双；": `Tổng điểm 4, 6, 8, 10, 12, 14 và 16 được coi là Chẵn;`,
    "出现围骰，庄家通吃，单/双均输。": `Nếu có ba viên xúc xắc giống nhau, nhà cái thắng tất cả và các cược 'Lẻ' và 'Chẵn' đều thua.`,
    "单点": `Đơn`,
    "根据所选择点数相符的骰子出现次数判断；": `Được xác định bởi số lần số đã chọn xuất hiện trên xúc xắc;`,
    "有一个骰子符合，则为单骰；": `Nếu một viên xúc xắc trùng, nó được coi là Trên đơn;`,
    "有两个骰子符合，则为双骰；": `Nếu hai viên xúc xắc trùng, nó được coi là Trên đôi;`,
    "有三个骰子符合，则为三骰。": `Nếu ba viên xúc xắc trùng, nó được coi là Trên ba;`,
    "和值": `Tổng`,
    "三个骰子的点数之和为和值；": `Tổng của ba viên xúc xắc được gọi là tổng;`,
    "和值范围为4-17点。不同和值具有不同赔率（详见赔率）。": `Tổng số nằm trong khoảng từ 4 đến 17 điểm. Các tổng khác nhau có tỷ lệ cược khác nhau (xem tỷ lệ cược để biết chi tiết).`,
    "牌九式": `Kết hợp`,
    "三颗骰子中，任意两颗骰子满足特定组合条件；": `Trong ba viên xúc xắc, bất kỳ hai viên nào đáp ứng các điều kiện kết hợp cụ thể;`,
    "总共15种开出的三颗骰子任意两个满足即可胜出。": `Tổng cộng có 15 loại kết hợp của ba viên xúc xắc, bất kỳ hai trong ba viên xúc xắc đáp ứng điều kiện sẽ mang lại chiến thắng.`,
    "对子": `Đôi`,
    "三颗骰子中，任意两颗骰子点数相同即为对子；": `Trong ba viên xúc xắc, bất kỳ hai viên nào có cùng số điểm tạo thành một đôi;`,
    "在6种对子玩法中押中，即可胜出。": `Chiến thắng xảy ra nếu đặt cược vào một trong sáu cặp có thể xảy ra.`,
    "全围": `Tam bảo bất kỳ`,
    "三颗骰子点数相同即全围；": `Cả ba viên xúc xắc hiển thị cùng một số được gọi là Tam bảo bất kỳ;`,
    "包含6种围骰组合，出现任意一种即可胜出。": `Bao gồm sáu loại kết hợp bộ ba, chiến thắng xảy ra nếu xuất hiện bất kỳ một loại nào.`,
    "围骰": `Tam bảo`,
    "投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。": `Đặt cược vào một bộ ba cụ thể, trong đó cả ba viên xúc xắc có cùng số. Chiến thắng xảy ra nếu điều kiện này được đáp ứng.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ cược`,
    "大": `Lớn`,
    "小": `Nhỏ`,
    "单骰": `Trên đơn`,
    "双骰": `Trên đôi`,
    "三骰": `Trên ba`,
    "对子（双骰、长牌）": `Đôi (Trên Đôi, Lá Bài Dài)`,
    "牌九式（骨牌、短牌）": `Kết Hợp (Domino, Lá Bài Ngắn)`,
    "点数和为4或17": `Tổng là 4 hoặc 17`,
    "点数和为5或16": `Tổng là 5 hoặc 16`,
    "点数和为6或15": `Tổng là 6 hoặc 15`,
    "点数和为7或14": `Tổng là 7 hoặc 14`,
    "点数和为8或13": `Tổng là 8 hoặc 13`,
    "点数和为9、10、11或12": `Tổng là 9, 10, 11 hoặc 12`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "游戏特点": `Tính năng Trò Chơi`,
    "是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。": `Đây là một trò chơi xúc xắc cổ đại của Trung Quốc, nơi người chơi đoán các con số, tổng, các kết hợp, v.v., của xúc xắc được tung. Trò chơi có nhiều lựa chọn cược và tỷ lệ cược hấp dẫn, làm cho nó rất phổ biến trong số các người chơi ở Trung Quốc và trên khắp châu Á.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "SeDie": {
    "色碟": `Xóc đĩa`,
    "游戏规则": `Quy tắc trò chơi`,
    "荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。": `Người chia bài đặt bốn nút hai màu (đỏ và trắng) vào một đĩa và đậy bằng một cái bát. Cái bát sau đó được lắc lên và xuống; sau khi hoàn thành hành động lắc, nó được đặt lên bàn cho phép các thành viên đặt cược. Khi thời gian đặt cược kết thúc, cái bát được mở ra và trả tiền dựa trên kết quả hiển thị.`,
    "玩法说明": `Giới thiệu cách chơi`,
    "大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码": `Lớn: Nếu nó hiển thị (4 đỏ), (3 đỏ, 1 trắng), nó được coi là 'Lớn'. Một kết hợp của hai đỏ và hai trắng được coi là hòa, và cược của người chơi được trả lại;`,
    "小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码": `Nhỏ: Nếu nó hiển thị (4 trắng), (3 trắng, 1 đỏ), nó được coi là 'Nhỏ'. Một kết hợp của hai đỏ và hai trắng được coi là hòa, và cược của người chơi được trả lại;`,
    "单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；": `

Lẻ: Nếu nó hiển thị (3 đỏ, 1 trắng), (3 trắng, 1 đỏ), nó được coi là 'Lẻ';`,
    "双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；": `

Chẵn: Nếu nó hiển thị (4 nút đỏ), (4 nút trắng), (2 trắng, 2 đỏ), nó được coi là 'Chẵn';`,
    "4（4红）：开出4个红色钮扣。": `4 (4 đỏ): Hiển thị 4 nút đỏ.`,
    "0（4白)：开出4个白色钮扣。": `0 (4 trắng): Hiển thị 4 nút trắng.`,
    "3(3红1白)：开出3个红色，1个白色钮扣。": `3 (3 đỏ, 1 trắng): Hiển thị 3 nút đỏ, 1 nút trắng.`,
    "1(3白1红)：开出3个白色，1个红色钮扣。": `1 (3 trắng, 1 đỏ): Hiển thị 3 nút trắng, 1 nút đỏ.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "下注组合": `Kết hợp Cược`,
    "赔率": `Tỷ lệ cược`,
    "大": `Lớn`,
    "小": `Nhỏ`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "4(4红)": `4 (4 Đỏ)`,
    "0(4白)": `0 (4 Trắng)`,
    "3(3红1白)": `3 (3 Đỏ, 1 Trắng)`,
    "1(3白1红)": `1 (3 Trắng, 1 Đỏ)`,
    "特别说明": `Hướng dẫn đặc biệt`,
    "本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。": `Trò chơi được tiến hành giống với môi trường sòng bạc thực tế. Trong trường hợp có bất kỳ tình huống đặc biệt nào, sẽ được xử lý theo thông báo của công ty.`,
    "荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。": `Nếu người chia bài vô tình làm rơi cái bát, đĩa hoặc nút trong quá trình chơi, họ sẽ đặt lại đồ vật và lắc lại.`,
    "若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。": `Nếu có sự chồng chéo của các nút làm cho không thể xác định kết quả, nhà cái sẽ tách các nút chồng chéo bằng que thủy tinh.`,
    "荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。": `Nếu người chia bài mở bát mà không lắc trước hoặc trước khi thời gian đặt cược kết thúc, trò chơi sẽ không hợp lệ và tất cả cược sẽ được hoàn lại.`,
    "荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。": `Khi người chia bài lắc và mở bát, nếu 【bát chạm vào nút】 kết quả cuối cùng sẽ được xác định bởi màu của các nút rơi xuống sau khi mở bát.`,
    "注意事项": `Những điều cần chú ý`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "AndarBahar": {
    "安达巴哈": `Andar Bahar`,
    "游戏规则": `Quy tắc trò chơi`,
    "安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；": `Andar Bahar là một trò chơi bài rất đơn giản sử dụng 1 bộ bài (không bao gồm các lá Joker);`,
    "荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；": `Người chia bài chia một lá bài ngửa ở vị trí Joker và sau đó tiếp tục chia bài ngửa vào cọc A (Andar) và B (Bahar);`,
    "出现一张牌和小丑牌的面值相同时，游戏结束。": `Trò chơi kết thúc khi một lá bài trùng với lá Joker xuất hiện.`,
    "玩法判定": `Cửa cược`,
    "安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。": `Andar và Bahar, cọc nào có lá bài khớp với lá Joker trước là người thắng cuộc.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ cược`,
    "安达": `Andar`,
    "巴哈": `Bahar`,
    "游戏特点": `Tính năng Trò Chơi`,
    "安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。": `Andar Bahar là một trong những trò chơi bài phổ biến nhất ở Ấn Độ, sự phổ biến của nó là do sự đơn giản chỉ với 2 cách chơi, dễ dàng và tốc độ cao.`,
    "注意事项": `Những điều cần chú ý`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "blackJack": {
    "21点": `Blackjack`,
    "游戏简述": `Giới thiệu`,
    "21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。": `21 hoặc còn được biết đến là Blackjack, bắt nguồn từ Pháp và đã lan rộng khắp thế giới với lịch sử lâu đời. Trò chơi sử dụng một bộ bài tiêu chuẩn gồm 52 lá, không bao gồm Joker. Mục tiêu cho người chơi là đạt giá trị bài càng gần 21 điểm càng tốt mà không vượt quá, sau đó so sánh bài với nhà cái.`,
    "玩法规则": `Quy tắc trò chơi`,
    "游戏规则": `Quy tắc trò chơi`,
    "7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。": `Cho phép 7 người chơi chính tại một bàn (số lượng không giới hạn của Bet Behind có thể tham gia); Mục tiêu trong Blackjack là có giá trị bài gần 21 hơn so với nhà cái mà không vượt quá 21. Trong Blackjack, A có thể tính là 1 hoặc 11, các lá bài mặt người (J, Q, K) tính là 10, và các lá bài số có giá trị bằng số điểm. Một Blackjack cao hơn 21.`,
    "如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。": `Nếu 2 lá bài đầu tiên là một "A" và một lá bài có giá trị 10 điểm, đó là Blackjack. Nếu người chơi là Blackjack nhưng nhà cái không phải, người chơi thắng X 1.5 cược (Ví dụ: nếu người chơi đặt cược 10, tiền thắng sẽ là 25).`,
    "出现一张牌和小丑牌的面值相同时，游戏结束。": undefined,
    "玩法判定": undefined,
    "如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。": `Nếu tổng điểm bài của người chơi gần 21 hơn nhà cái, người chơi sẽ thắng số tiền cược (Ví dụ: nếu người chơi cược 10, tiền thắng sẽ là 20).`,
    "如果您的牌的总点数超过21点，您“爆牌”并失去赌注。": `Nếu tổng điểm bài của bạn >21 điểm, bạn "Bù bài" và mất tiền cược.`,
    "如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。": `Nếu người chơi và nhà cái có cùng tổng điểm (17 trở lên), không bên nào thắng và cược của người chơi được hoàn lại, được coi là hòa.`,
    "庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。": `Nhà cái nhận thêm lá bài cho đến 16 điểm và dừng lại ở ≥ 17 điểm (bao gồm cả soft 17)`,
    "游戏流程": `Quá trình chơi`,
    "投注主赌注后，可以投注边注玩法（完美对子、21+3）。": `Người chơi có thể đặt cược phụ (Đôi hoàn hảo, 21+3) sau khi đặt cược chính đã được xác nhận.`,
    "投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。": `Sau vòng đặt cược,sử dụng các nút Hit, Stand, Double, Split & Insurance (nếu lá bài đầu tiên của nhà cái là "A") bấm nút để tham gia trò chơi.`,
    "如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。": `Dừng tự động nếu không chọn thao tác, nếu bạn chọn nhân đôi, số tiền bằng với cược chính sẽ được trừ từ số dư của bạn và cược chính của bạn sẽ được nhân đôi.`,
    "要牌": `Rút`,
    "选择“要牌”继续游戏并再发一张牌。": `Chọn Rút để tiếp tục trò chơi và nhận thêm lá bài.`,
    "停牌": `Dừng`,
    "选择“停牌”则该手牌的当前回合结束。": `Chọn Dừng sẽ kết thúc ván hiện tại của tay bài.`,
    "分牌": `Tách`,
    "如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。": `Nếu tay bài ban đầu của bạn là một cặp bài có giá trị bằng nhau (chú ý:10, J, Q, K đều tính là 10 điểm), bạn có thể quyết định tách đôi để tạo hai tay bài riêng biệt. Mỗi tay có cược riêng bằng với cược chính của bạn. Bạn có thể Rút bài riêng cho từng tay. Sau khi các lá bài đã được tách, chúng không thể tách lại nữa.`,
    "对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。": `Với mỗi tay bài được tách, bạn có thể rút bất kỳ số lượng bài nào; tuy nhiên, nếu bạn tách đôi hai lá Ách, bạn chỉ có thể rút một lá bài bổ sung cho mỗi lá Ách. Nếu sau khi tách bài, bạn nhận được một lá Ách và một lá bài mười điểm, tổng điểm sẽ tính là 21, không phải Blackjack. Trong trường hợp này, tiền thắng là 1:1, không phải 1:1.5.`,
    "注：分牌后不可以投注完美对子玩法。": `Lưu ý: Cược vào Cặp Hoàn hảo không được phép sau khi Tách.`,
    "加倍": `Gấp đôi`,
    "在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。": `Sau khi bạn được chia hai lá bài đầu tiên, nếu bạn nghĩ rằng bạn có thể thắng tay bài của nhà cái bằng một lá bài thứ ba, bạn có thể Gấp đôi cược của mình. Cược của bạn sẽ tăng gấp đôi từ cược ban đầu của bạn (trừ từ số dư của bạn) và bạn sẽ được chia thêm một lá bài.`,
    "您可以在分牌后加倍。": `Có thể Gấp đôi sau khi Tách.`,
    "如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。": `Nếu người chơi gấp đôi và lá bài đầu tiên của nhà cái là A, và nhà cái được Blackjack, bạn sẽ mất cả hai cược (cược ban đầu và cược gấp đôi).`,
    "如果庄家第一张牌点数是10，且庄家获得Blackjack，则加倍赌注返还给您。": `Nếu lá bài đầu tiên của nhà cái là 10 và nhà cái được Blackjack, cược gấp đôi sẽ được trả lại cho bạn.`,
    "保险": `Bảo hiểm`,
    "如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。": `Nếu lá bài đầu tiên của nhà cái là "A", người chơi có thể mua Bảo hiểm. Nếu người chơi mua Bảo hiểm, một cược bổ sung bằng một nửa cược chính ban đầu sẽ được đặt lên bàn.`,
    "如果庄家的确有Blackjack，您将按1：2获得保险赔付。": `Nếu nhà cái là Blackjack, người chơi có thể nhận tiền bảo hiểm với tỷ lệ 1:2.`,
    "如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。": `Nếu nhà cái không phải Blackjack, dù người chơi thắng hay thua, cược bảo hiểm sẽ bị mất.`,
    "10牌自动胜": `10 lá bài tự động thắng`,
    "理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。": `Nếu người chơi rút 10 lá bài mà không bị Bust, người chơi sẽ thắng tự động, trừ khi nhà cái có Blackjack.`,
    "多座位功能": `Nhiều chỗ ngồi`,
    "我们提供单座模式桌台和双座模式桌台。": `Các bàn Blackjack ghế đơn và ghế đôi có sẵn.`,
    "在单座模式桌台内，每个玩家最多可以使用一个座位。": `Mỗi người chơi có thể sử dụng tối đa 1 ghế trên bàn Ghế Đơn.`,
    "在双座模式内，玩家可以占据最多2个座位，并在每个座位上分别投注。": `Mỗi người chơi có thể sử dụng tối đa 2 ghế trên bàn Ghế Đôi và đặt cược riêng biệt.`,
    "如果玩家在赌桌上没有已确认的赌注，玩家可以在两局之间或单独游戏局中更换座位。": `Nếu người chơi chưa xác nhận cược, họ có thể đổi chỗ giữa 2 trò chơi hoặc trong trò chơi.`,
    "如果玩家在赌桌上有已确认的赌注，玩家仅可以占据和离开没有投注的座位。": `Nếu người chơi đã xác nhận cược, người chơi chỉ có thể chiếm giữ và rời khỏi những chỗ ngồi không có cược.`,
    "旁注功能（座位后投注）": `Cược sau `,
    "本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。": `Trò chơi này cung cấp tính năng Cược Phía Sau. Điều này cho phép bạn tham gia trò chơi bằng cách theo dõi thao tác của người chơi chính và đặt cược vào tay bài của họ.`,
    "只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。": `Tùy chọn Cược Phía Sau chỉ có sẵn ở các vị trí mà người chơi chính đã chiếm. Bạn có thể là người chơi Cược Phía Sau trên nhiều vị trí cùng một lúc. Khi tham gia với tư cách là người chơi chính, bạn cũng có thể đặt Cược Phía Sau trên tất cả các vị trí khác trừ vị trí của mình.`,
    "作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。": `Là một người chơi Cược Phía Sau, bạn sẽ tự động theo dõi các hành động của người chơi chính, trừ khi họ chọn Tách hoặc Gấp đôi và bạn không có đủ tiền. Trong trường hợp này, nếu người chơi chính Gấp đôi cược, bạn sẽ nhận và Dừng; nếu người chơi chính Tách, bạn sẽ theo dõi tay bài chính bên phải.`,
    "如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。": `Nếu bạn đặt cược Cược Phía Sau vào tay bài của một người chơi nhưng người chơi quyết định không đặt cược trong vòng cược, cược của bạn sẽ được hoàn trả vào tài khoản của bạn.`,
    "热门玩家图标帮助您了解当前哪位玩家连胜最高。": `Biểu tượng "Người Chơi nổi bật" giúp bạn thấy người chơi nào hiện đang có chuỗi chiến thắng dài nhất.`,
    "数字表示该玩家连胜的局数。如果该玩家输了一局，他将失去热门玩家图标。": `Số này đại diện cho số trận thắng liên tiếp của người chơi đó. Nếu người chơi thua một trận, họ sẽ mất biểu tượng "Người Chơi nổi bật".`,
    "发牌方式": `Phương thức phát bài`,
    "我们采用欧式发牌方式。": `Trò chơi Blackjack sử dụng Phong Cách phát Bài Châu Âu.`,
    "庄家在所有玩家都做出决定后获得第二张牌。": `Nhà cái rút lá bài thứ hai sau khi tất cả hành động của người chơi đã hoàn thành.`,
    "发牌方式会影响保险和操作回合的顺序：": `Phong cách phát bài ảnh hưởng đến Bảo hiểm và trình tự trò chơi:`,
    "对于欧式发牌，当您获得第二张牌且庄家的第一张牌是A时，将提供保险选项。不过您会在保险回合后直接进入操作回合，所有玩家都操作完毕后会将第二张牌发给庄家。": `Đối với các giao dịch kiểu Châu Âu, khi bạn được phát lá bài thứ hai và lá bài đầu tiên của nhà cái là một A, tùy chọn bảo hiểm sẽ được cung cấp. Tuy nhiên, bạn sẽ trực tiếp đến vòng hành động sau vòng bảo hiểm, và lá bài thứ hai sẽ được phát cho nhà cái sau khi tất cả người chơi đã hoàn thành hành động của họ.`,
    "关于21点游戏桌台，我们存在以下2种:": `Có 2 loại bàn Blackjack có sẵn:`,
    "1.使用8副牌透明牌靴的桌台，其标志为": `1. Bàn Blackjack sử dụng bộ bài trong suốt 8 cỗ bài được đánh dấu là.`,
    "2.使用6副牌洗牌机的桌台，其标志为": `2. Bàn Blackjack sử dụng bộ xào bài 6 cỗ bài được đánh dấu là.`,
    "边注": `Cược Bên`,
    "这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。": `Đặt cược chính và kích hoạt cược bên.`,
    "21+3": `21+3`,
    "玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。": `Người chơi có thể đặt cược vào 2 lá bài đầu tiên của người chơi và lá bài đầu tiên của nhà cái.`,
    "同花三条：所有三张牌的点数和花色都相同": `Bộ ba đồng chất: 3 lá bài cùng điểm và cùng chất.`,
    "同花顺：所有三张牌花色相同、点数依次排列": `Thùng phá sảnh: 3 lá bài cùng chất theo thứ tự.`,
    "三条：所有三张牌大小相同": `Bộ ba: 3 lá bài cùng hạng.`,
    "顺子：所有三张牌花色不同但点数依次排列": `Sảnh: 3 lá bài không cùng chất, điểm theo thứ tự.`,
    "同花：所有三张牌花色相同": `Thùng: 3 lá bài cùng chất.`,
    "同花三条": `Bộ ba đồng chất`,
    "(完全相同的三张牌)赔率 1：100": `Bộ ba đồng chất

tỷ lệ cược 1:100`,
    "同花顺": `Thùng phá sảnh`,
    "（同花顺子,三张同花顺序）赔率 1：40": `Thùng phá sảnh) 

tỷ lệ cược 1:40`,
    "三条": `Bộ ba`,
    "（三张相同牌值的非同花牌）赔率 1：30": `Bộ ba

tỷ lệ cược 1:30`,
    "顺子": `Sảnh`,
    "（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10": `Sảnh

tỷ lệ 1:10`,
    "同花": `Thùng`,
    "（三张同一花色的牌）赔率 1：5": `Thùng

tỷ lệ 1:5`,
    "以玩家首2张牌算": `Tính toán hai lá bài đầu tiên của người chơi.`,
    "完美对子": `Đôi Hoàn Hảo`,
    "（闲家一对同色同花同点值）赔率 1：25": `(1 đôi cùng chất và cùng hạng) Tỷ lệ cược 1:25`,
    "同色对子": `Đôi Cùng Màu`,
    "（闲家一对同色同点值不同花）赔率 1：12": `(1 đôi cùng màu và cùng hạng, nhưng khác chất) Tỷ lệ cược 1:12`,
    "混合对子": `Đôi Hỗn Hợp`,
    "（闲家一对不同色不同花同点值）赔率 1：6": `(1 đôi cùng hạng nhưng khác màu và chất) Tỷ lệ cược 1:6`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "主游戏": `Trò Chơi Chính`,
    "赔率": `Tỷ lệ cược`,
    "投注项目": `Mục đặt cược`,
    "主赌注": `Cược ban đầu`,
    "Blackjack": `Blackjack`,
    "21+3边注玩法": `21+3 `,
    "完美对子边注玩法": `Đôi hoàn hảo`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。": `Tất cả người chơi phải đặt cược vào Ante để được phép đặt cược vào Đôi Hoàn Hảo và 21+3.`,
    "如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。": `Nếu tất cả người chơi đều vượt quá 21 điểm, trò chơi sẽ kết thúc; Nhà cái không lấy thêm bài nếu tất cả người chơi vượt quá 21 điểm, trừ khi lá bài đầu tiên của Nhà cái là A, khi đó Nhà cái sẽ lấy thêm 1 lá để xác định xem có phải Blackjack không.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。": `Nếu có lỗi xác định hệ thống xảy ra trong khi tiết lộ lá bài, nhà cái sẽ xác định lại bằng cách sử dụng hệ điều hành cho đến khi thành công (tất cả cược/thanh toán sẽ bị hủy nếu không thể tiết lộ lá bài).`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "若玩家已经是Blackjack后出现系统故障，该玩家仍然按照1:1.5进行派彩。": `Nếu người chơi đã đạt được Blackjack và xảy ra lỗi hệ thống, người chơi vẫn sẽ được thanh toán với tỷ lệ 1:1,5.`,
    "如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。": `Nếu bạn bị ngắt kết nối khỏi trò chơi, bất kỳ cược nào đã đặt sẽ vẫn còn hiệu lực và sẽ được xử lý trong khi bạn vắng mặt. Khi bạn kết nối lại, bạn sẽ có thể xem kết quả trong Lịch sử Cược của mình.`
  },
  "Roulette": {
    "轮盘": `Roulette`,
    "游戏规则": `Quy tắc trò chơi`,
    "荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；": `Người chia bài quay bánh xe roulette và ném một quả bóng ngà vào rãnh ngoài của bánh xe;`,
    "当滚球在轮盘内转动多周后减速并落入下方格子；": `Khi quả bóng chậm lại sau một vài lần quay, nó sẽ rơi vào một trong các ô;`,
    "该格子所对应的数字，作为该局结果；": `Số tương ứng với túi đó xác định kết quả của vòng;`,
    "若滚球在轮盘转动小于或等于5圈，需要重新打球。": `Nếu quả bóng quay ít hơn hoặc bằng năm vòng, cần phải quay lại.`,
    "玩法判定": `Cửa cược`,
    "美式注盘": `Cược kiểu Mỹ`,
    "美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。": `Bố cục cược kiểu Mỹ, còn được gọi là bố cục cổ điển, bao gồm các cược thẳng và các tổ hợp cược khác nhau. Người chơi có thể chuyển đổi giữa bố cục cược kiểu Mỹ và Pháp trong suốt trò chơi.`,
    "美式-直注": `Kiểu Mỹ - Cược Thẳng`,
    "即直接点击数字方块，对0-36的数字直接下注。": `Nhấp trực tiếp vào ô số để đặt cược vào các số từ 0-36.`,
    "例如，下注11点，只有当结果是11时才会胜出。": `Ví dụ: Cược vào 11, bạn chỉ thắng nếu kết quả là 11.`,
    "美式-分注": `Kiểu Mỹ - Cược Tách`,
    "即在线上下注，当结果为线两边的数字时胜出。": `Đặt cược trực tuyến; bạn sẽ thắng nếu kết quả là số nằm ở một trong hai phía của đường.`,
    "例如，下注3和6之间的线，或者2和3之间的线。": `Ví dụ: Cược vào đường giữa 3 và 6, hoặc đường giữa 2 và 3.`,
    "美式-三数": `Kiểu Mỹ - Cược Bộ ba`,
    "三数为同时投注包含0在内的三个数字。": `Cược vào ba số cùng lúc, bao gồm cả số 0 trong nhóm.`,
    "例如，0，1，2为一组。0，2，3为一组。": `Ví dụ: 0, 1, 2 là một nhóm. 0, 2, 3 là nhóm khác.`,
    "美式-四号": `Kiểu Mỹ - Cược Bộ bốn đầu`,
    "同时对0，1，2，3四个数字进行下注。": `Đặt cược đồng thời vào các số 0, 1, 2 và 3.`,
    "美式-角注": `Kiểu Mỹ - Cược Góc`,
    "对4个数字方框交叉处点击。": `Nhấp vào giao điểm của bốn ô số.`,
    "例如：1，2，4，5为一注。2，3，5，6为一注。": `Ví dụ: 1, 2, 4, 5 là một cược. 2, 3, 5, 6 là cược khác.`,
    "美式-街注": `Kiểu Mỹ - Cược Hàng`,
    "对3个相连的数字投注。": `Cược vào ba số liên tiếp.`,
    "例如：1，2，3为一组。7，8，9为一组。": `Ví dụ: 1, 2, 3 là một nhóm. 7, 8, 9 là nhóm khác.`,
    "美式-线注": `Kiểu Mỹ - Cược Dòng`,
    "类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。": `Tương tự như cược đường, nhưng khác biệt nằm ở chỗ đặt cược vào giao điểm của hai cược đường, bao gồm sáu số.`,
    "例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。": `Ví dụ: 1, 2, 3, 4, 5, 6 là một cược. 4, 5, 6, 7, 8, 9 là cược khác.`,
    "美式-列注": `Kiểu Mỹ - Cược Cột`,
    "每列12个号码，对“第X列”处下注。": `Mỗi cột có 12 số, đặt cược vào "Cột X" để tham gia cược.`,
    "例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。": `Ví dụ: Cột đầu tiên bao gồm 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, tổng cộng 12 số.`,
    "美式-打注": `Kiểu Mỹ - Cược Tá`,
    "将筹码下到“第X打”的位置。": `Đặt chip vào vị trí "Tá X".`,
    "例如：第一打包括1-12。第二打为13-24。第三打为25-36。": `Ví dụ: Tá đầu tiên bao gồm các số 1-12. Tá thứ hai là 13-24. Tá thứ ba là 25-36.`,
    "美式-红黑": `Kiểu Mỹ - Đỏ/Đen`,
    "对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。": `Đặt cược vào các vị trí đánh dấu màu đỏ hoặc đen trên bàn. Nếu 0 được rút ra, tất cả các cược cho cả màu đỏ và đen đều thua.`,
    "例如：所有为红色格子的数字或所有黑色格子的数字。": `Ví dụ: Tất cả các con số trong ô màu đỏ hoặc tất cả các con số trong ô màu đen.`,
    "美式-单双": `Kiểu Mỹ - Lẻ/Chẵn`,
    "对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。": `Đặt cược vào các vị trí đánh dấu lẻ hoặc chẵn trên bàn. Nếu 0 được rút ra, tất cả các cược cho cả lẻ và chẵn đều thua.`,
    "例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。": `Ví dụ: Số lẻ bao gồm 1, 3, 5...35. Số chẵn là 2, 4, 6...36.`,
    "美式-大小": `Kiểu Mỹ - Lớn/Nhỏ`,
    "对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。": `Đặt cược vào các vị trí đánh dấu lớn hoặc nhỏ trên bàn. Nếu 0 được rút ra, tất cả các cược cho cả lớn và nhỏ đều thua.`,
    "例如：小（1-18）。大（19-36）。": `Ví dụ: Nhỏ (1-18). Lớn (19-36).`,
    "法式注盘": `Cược kiểu Pháp`,
    "也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。": `Còn được gọi là bố trí cược châu Âu, có thể coi là một biến thể của bố trí kiểu Mỹ. Đây là phương pháp cược trong đó các phần của bố trí kiểu Mỹ được gộp lại để đặt cược.`,
    "法式-相邻投注：": `Kiểu Pháp - Cược Neighbors`,
    "点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。": `Nhấp vào lưới của bánh xe roulette kiểu Pháp, đặt cược vào số mà bạn nhấp và các số láng giềng ngay bên cạnh ở cả hai bên (tổng cộng 5 số).`,
    "例如：选中5，则投注号码为23，10，5，24，16。": `Ví dụ: Nếu bạn chọn 5, thì cược vào các số 23, 10, 5, 24, 16.`,
    "法式-轮上零旁：": `Kiểu Pháp - Orphelins:`,
    "点击轮上零旁区域。": `Nhấp vào khu vực bên cạnh số không trên bánh xe.`,
    "对0和3；12和15；32和35；26；各下一注。": `Cược vào các tổ hợp 0 và 3; 12 và 15; 32 và 35; 26; mỗi tổ hợp một lần cược.`,
    "法式-零旁注上角：": `Kiểu Pháp - Voisins:`,
    "点击零旁注上角区域，对以下区域和组合进行下注。": `Nhấp vào góc trên của Cược góc bên cạnh số 0, đặt cược vào các khu vực và tổ hợp sau.`,
    "4和7；12和15；18和21；19和22；32和35；5个组合各一注；": `4 và 7; 12 và 15; 18 và 21; 19 và 22; 32 và 35; năm tổ hợp, mỗi tổ hợp một lần cược;`,
    "0，2，3的组合，下两注；": `Kết hợp 0, 2, 3; đặt hai cược;`,
    "25，26，28，29的组合，下两注。": `Kết hợp 25, 26, 28, 29; đặt hai cược.`,
    "法式-轮上孤注：": `Kiểu Pháp - Tier:`,
    "点击轮盘孤注，对以下区域组合进行下注。": `Nhấp vào phần cược đơn của bánh xe roulette, đặt cược vào các kết hợp khu vực sau.`,
    "即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。": `Đặt cược vào các số 1, 6, 9; 14, 17; 20, 31 và 34, mỗi số một lần cược.`,
    "法式-轮盘下角注：": `Pháp - Cược Góc Dưới:`,
    "法式-轮盘下角注。": `Pháp - Cược Góc Dưới.`,
    "5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。": `5 và 8; 10 và 11; 13 và 16; 23 và 24; 27 và 30; 33 và 36; mỗi cược cho mỗi trong sáu ván này.`,
    "小球落入的格子对应信息包含数字，红黑。数字分大小，单双；": `Thông tin tương ứng với ô bóng rơi bao gồm các số, màu đỏ và đen. Các số được chia thành lớn và nhỏ, lẻ và chẵn;`,
    "同时根据数字在下注盘的位置，分为“打”和“列”；": `Dựa trên vị trí số trên bố cục đặt cược, chúng được phân loại thành 'tá' và 'cột';`,
    "也有根据数字所在下注盘位置，分为不同组的玩法。": `Cũng có các cược dựa trên vị trí số trong bố cục đặt cược, được chia thành các nhóm trò chơi khác nhau.`,
    "赔率表": `Bảng tỷ lệ`,
    "由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。": `Bố cục cược của Pháp chỉ là một phiên bản gói của bố cục cược Mỹ, với tỷ lệ tương ứng với bố cục cược Mỹ; do đó, tỷ lệ chủ yếu dựa trên bố cục Mỹ.`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ cược`,
    "直注": `Cược Thẳng`,
    "分注": `Cược Tách`,
    "街注": `Cược Hàng`,
    "三数": `Cược Bộ ba`,
    "角注": `Cược Góc`,
    "四号": `Cược Bộ bốn đầu`,
    "线注": `Cược Dòng`,
    "列注": `Cược Cột`,
    "打注": `Cược Tá`,
    "红/黑": `Đỏ/Đen`,
    "单/双": `Lẻ/Chẵn`,
    "大/小": `Lớn/Nhỏ`,
    "游戏特点": `Tính năng Trò Chơi`,
    "欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。": `Roulette là trò chơi cá cược phổ biến ở Châu Âu và Hoa Kỳ, từng phổ biến trong giới quý tộc và giới thượng lưu, và chỉ gần đây mới trở thành giải trí cho quần chúng trong xã hội hiện đại. Nó được biết đến là trò chơi cao quý nhất. Các phương pháp đặt cược sau đây nổi tiếng vì tính hấp dẫn đa chiều, trong đó các cược thẳng đơn lẻ được theo đuổi cho tỷ lệ cao và nhiều cược được thực hiện để tăng cơ hội chiến thắng, cân bằng giữa giải trí và hứng khởi.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "Fantan": {
    "番摊": `FanTan`,
    "游戏规则": `Quy tắc trò chơi`,
    "游戏使用钮扣200颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。": `Trò chơi  sử dụng 200 hạt. Sau khi bắt đầu vòng chơi mới, người chia bài sử dụng một chiếc bát úp ngược để chọn ngẫu nhiên một số hạt từ một chồng hạt, sau đó bắt đầu đặt cược.`,
    "下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。": `Sau khi kết thúc việc đặt cược, người chia bài sẽ tiết lộ nội dung của bát và sử dụng một cái gậy để sắp xếp các nút thành các hàng bốn cho đến khi còn lại bốn hoặc ít hơn bốn nút.`,
    "最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。": `Số nút còn lại ở cuối sẽ xác định kết quả của vòng chơi và màn hình sẽ hiển thị liệu người chơi đã thắng hay thua.`,
    "玩法判定": `Cửa cược`,
    "单：1、3为单；": `Lẻ: 1, 3 là số lẻ;`,
    "双：2、4为双。": `Chẵn: 2, 4 là số chẵn.`,
    "番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；": `Fan: Đặt cược vào một số duy nhất, thắng nếu số đó xuất hiện, thua nếu số khác xuất hiện. Ví dụ: Đặt cược vào 1 Fan, thắng nếu 1 được rút, thua nếu 2, 3 hoặc 4 được rút;`,
    "念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；": `Nim: Đặt cược vào hai số, một cho thắng, một cho hòa, hai số còn lại thua. Ví dụ: Đặt cược vào 1 Nim 2, thắng nếu 1 được rút, hòa nếu 2 được rút, thua nếu 3 hoặc 4 được rút;`,
    "角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；": `Kwok: Đặt cược vào hai số, thắng nếu một trong số đó được rút, thua nếu hai số còn lại được rút. Ví dụ: Đặt cược vào 1 2 Kwok, thắng nếu 1 hoặc 2 được rút, thua nếu 3 hoặc 4 được rút;`,
    "通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；": `Nga: Đặt cược vào hai số, nếu một trong số đó thắng, hai số còn lại sẽ dẫn đến một số thua và một số hòa.Ví dụ: Đặt cược vào 1 2 Nga,nếu ra số 1 hoặc 2 thì thắng,số 3 thua và số 4 hoà.`,
    "三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；": `SsH: Đặt cược vào ba số, thắng nếu bất kỳ số nào trong số đã cược được rút, thua nếu bất kỳ số khác được rút. Ví dụ: Đặt cược vào 1 2 3 SsH, thắng nếu 1 2 hoặc 3 được rút, thua nếu 4 được rút;`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ cược`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "番": `Fan`,
    "念": `Nim`,
    "角": `Kwok`,
    "通": `Nga`,
    "三门": `SsH`,
    "游戏特点": `Tính năng Trò Chơi`,
    "番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。": `FanTan là một trò chơi cổ điển của người Trung Quốc, phổ biến ở các khu vực Quảng Đông và Quảng Tây. Ban đầu, FanTan chỉ có cách chơi 'Fan', đó là cách mà nó có tên. Ngày nay, lối chơi của FanTan đã đa dạng hóa đáng kể, bao gồm các tùy chọn đặt cược bổ sung như Nim, Kwok, Nga, SsH và Lẻ/Chẵn.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "FriedGoldenFlower": {
    "炸金花": `Nổ kim hoa`,
    "游戏规则": `Quy tắc trò chơi`,
    "使用一副牌游戏（去大小王）进行游戏；": `Sử dụng bộ bài tiêu chuẩn (Joker đã bị loại trừ)`,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。": `Đầu tiên, so sánh thứ hạng của các tay bài nếu các bộ bài giống nhau thì sẽ so sánh từng lá bài từ lớn đến nhỏ, để quyết định thắng thua.`,
    "玩法判定": `Cửa cược`,
    "牌型": `Kiểu bài`,
    "豹子杀手：": `Sát thủ bão:`,
    "三张非同花的2，3，5；": `Ba lá 2, 3, 5 không cùng chất`,
    "豹子：": `Bão:`,
    "三张点数相同的牌；": `Ba lá bài cùng hạng;`,
    "同花顺：": `Thùng phá sảnh：`,
    "花色相同的顺子牌；": `Các lá bài cùng chất liên tiếp;`,
    "同花：": `Thùng:`,
    "花色相同的任意三张牌；": `Ba lá bài bất kỳ có cùng chất;`,
    "顺子：": `Sảnh:`,
    "三张连续的牌；": `Ba lá bài liên tiếp;`,
    "对子：": `Đôi:`,
    "两张点数相同的牌；": `Hai lá bài cùng hạng;`,
    "散牌：": `Bài lẻ:`,
    "无法组成任何类型的牌；": `Không thể tạo bất kỳ loại bài nào;`,
    "牌型大小": `So Kiểu bài`,
    ">豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Bão > Thùng Phá sảnh > Thùng > Sảnh > Đôi > Bài Lẻ;`,
    "豹子杀手＞豹子；": `Sát thủ bão > Bão;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `Khi có cả tay Sát thủ bão và Bão, tay Sát thủ bão mạnh hơn Bão;`,
    "散牌＞豹子杀手；": `Bài Lẻ > Sát thủ bão;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `Nếu không có tay Bão, Sát thủ bão là tay Bài Lẻ thấp nhất.`,
    "对子大小": `Đôi lớn nhỏ`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `Nếu cả hai người chơi đều có tay Đôi, so sánh thứ hạng của các Đôi; Đôi A > Đôi K > Đôi Q > Đôi J > Đôi 10 > Đôi 9 > Đôi 8 > Đôi 7 > Đôi 6 > Đôi 5 > Đôi 4 > Đôi 3 > Đôi 2;`,
    "两对对子的点数相同，再比单牌点数大小。": `Nếu thứ hạng của hai đôi giống nhau, so sánh thứ hạng của từng lá bài.`,
    "豹子、同花、散牌牌型": `Bão, Thùng, Tay Bài Lẻ`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2。": `So sánh giá trị điểm của lá bài cao nhất; nếu giống nhau, so sánh giá trị điểm của lá bài cao thứ hai, và cứ tiếp tục như vậy; Xét về giá trị điểm: A > K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2. `,
    "同花顺，顺子": `Thùng phá sảnh, Sảnh`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `So điểm theo thứ tự: AKQ>KQJ>......>432>32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Lưu ý: Chỉ trong Thùng Phá Sảnh và Sảnh, A mới được xem là quân bài lớn nhất hoặc nhỏ nhất; trong các loại bài khác, A là quân bài đơn lớn nhất`,
    "赔率表": `Bảng tỷ lệ`,
    "玩家赢赔率": `Tỷ Lệ Thắng của Người Chơi`,
    "玩家输赔率": `Tỷ Lệ Thua của Người Chơi`,
    "胜方牌型": `Kiểu bài bên thắng`,
    "平倍": `Thường`,
    "翻倍": `Gấp bội`,
    "超倍": `Siêu bội`,
    "单牌": `Bài lẻ`,
    "单牌以下": `Bài lẻ {value} trở xuống`,
    "对子": `Đôi`,
    "顺子": `Sảnh`,
    "同花": `Thùng`,
    "同花顺": `Thùng phá sảnh`,
    "三条": `Bão`,
    "豹子杀手": `Sát thủ bão`,
    "游戏特点": `Tính năng Trò Chơi`,
    "炸金花，也被称为赢三张。是一种广泛流传的民间游戏。": `Bài 3 lá, còn được gọi là "Thắng Ba Lá," là một trò chơi bài dân gian phổ biến và được truyền bá rộng rãi.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  }
};

export default data;