const data ={
    "Header": {
      "d1": `語言設定`,
      "d2": `語言是否切換為`,
      "d3": `取消`,
      "d4": `確定`
    },
    "Bacarat": {
      "d1": `經典百家樂`,
      "d2": `1. 遊戲規則`,
      "d3": `採用8副牌（去大小王），共計416張；`,
      "d4": `“閒家“ ”莊家“ 各派兩張牌，先 “閒” 後 “莊”（開牌順序亦同），如首輪未分勝負，再按 ”牌例“ 博牌，最多各發1張牌定輸贏；`,
      "d5": `任何一家拿到8點或9點（天生贏家），牌局會使用前4張牌進行結算，不再博牌；`,
      "d6": `最終以閒家和莊家的和值個位比大小`,
      "d7": `2. 博牌牌例`,
      "d8": `當莊和閒首輪開牌後，判斷是否需要博牌。先判斷閒，再判斷莊。詳見下表：`,
      "d9": `閒博牌`,
      "d10": `閒兩牌合計點數`,
      "d11": `(閒家)`,
      "d12": `必須博牌`,
      "d13": `不得博牌`,
      "d14": `例牌，即定勝負`,
      "d15": `莊博牌`,
      "d16": `莊兩牌合計點數`,
      "d18": `(莊家)`,
      "d19": `必須博牌`,
      "d20": `若閒家博得第三張牌的牌值為8點，莊家不得博牌`,
      "d21": `若閒家博得第三張牌的牌值為0，1，8，9點， 莊家不得博牌`,
      "d22": `若閒家博得第三張牌的牌值為0，1，2，3，8，9點， 莊家不得博牌`,
      "d23": `若閒家博得第三張牌的牌值是6 或 7點，莊家必須博牌`,
      "d24": `3. 點數計算`,
      "d25": `A-9點，根據其牌面數值為點數，其中A對應1點。`,
      "d26": `10或J或Q或K，為0點。`,
      "d27": `例：`,
      "d28": `4. 玩法判定`,
      "d29": `4.1. 莊&閒&和：`,
      "d30": `莊家點數大於閒家，則下注 “莊” 者獲得獎勵；`,
      "d31": `莊家：`,
      "d32": `閒家：`,
      "d33": `閒家點數大於莊家，則下注 “閒” 者獲得獎勵；`,
      "d34": `若莊閒點數相同，則下注 “和” 者獲得獎勵。`,
      "d35": `4.2. 莊對&閒對：`,
      "d36": `當前局中，莊或閒的前兩張牌的牌面（僅數字或字母，不計花色）為對子；`,
      "d37": `莊/閒家：`,
      "d38": `莊家前兩張為對子，為莊對。閒家前兩張為對子，為閒對；`,
      "d39": `當出現對子，下注 “莊對” 或 “閒對” ，即可獲得對應賠率的獎勵。`,
      "d40": `4.3. 任意對子：`,
      "d41": `指莊或閒前兩張牌為對子（詳見莊對&閒對）;`,
      "d42": `無論出現莊對或閒對，下注 “任意對子” 者獲得獎勵。`,
      "d43": `4.4. 完美對子：`,
      "d44": `指莊或閒前兩張牌為對子（詳見莊對&閒對），同時滿足花色相同條件；`,
      "d45": `無論莊或閒出現完美對子，下注 “完美對子” 者均可獲得獎勵。`,
      "d46": `4.5. 超級對：`,
      "d47": `首4張中包含兩張或以上的相同牌面（不論花色）。`,
      "d48": `4.6. 龍7：`,
      "d49": `莊家以三張牌7點勝。`,
      "d50": `4.7. 熊貓8：`,
      "d51": `閒家以三張牌8點勝。`,
      "d52": `4.8. 大老虎&小老虎：`,
      "d53": `莊家以三張牌6點勝為大老虎；`,
      "d54": `莊家以兩張牌6點勝為小老虎。`,
      "d55": `4.9. 老虎和：`,
      "d56": `莊閒分別以6點和局。`,
      "d57": `4.10. 老虎對：`,
      "d58": `根據莊閒前4張牌的結果，符合下面3種結果之一時，獲得不同的賠率：`,
      "d59": `計莊對、閒對，且為相同牌面對子；`,
      "d60": `計莊對、閒對；`,
      "d61": `計莊對或閒對；`,
      "d62": `和局不退還下注。`,
      "d63": `4.11. 莊天牌&閒天牌：`,
      "d64": `莊家首兩張合計為8或者9點，且莊勝，則下注 “莊天牌” 者獲得獎勵；`,
      "d65": `閒家首兩張合計為8或者9點，且閒勝，則下注 “閒天牌” 者獲得獎勵。`,
      "d66": `4.12. 天牌：`,
      "d67": `莊家或閒家首兩張牌合為8或者9點即可勝出。`,
      "d68": `4.13. 龍&虎&和：`,
      "d69": `莊家第一張牌點數，大於閒家第一張牌（10，J，Q，K分別為10，11，12，13點）為龍贏；`,
      "d70": `閒家第一張牌點數，大於莊家第一張牌（10，J，Q，K分別為10，11，12，13點）為虎贏；`,
      "d71": `莊閒家第一張牌點數相同（10，J，Q，K分別為10，11，12，13點）為龍虎和。`,
      "d72": `4.14. 超級六：`,
      "d73": `免佣和非免佣模式下都可押注；`,
      "d74": `當莊家未博牌，點數和個位為6，並且點數大過閒，則為12倍賠率；`,
      "d75": `當莊家博牌，其點數和個位為6， 並且點數大過閒，則為20倍賠率。`,
      "d76": `4.15. 莊龍寶&閒龍寶：`,
      "d77": `莊以例牌勝出，則莊龍寶勝出；`,
      "d78": `閒以例牌勝出，則閒龍寶勝出；`,
      "d79": `莊非例牌，莊點數-閒點數=N，若N=4~9，則莊龍寶勝出；`,
      "d80": `閒非例牌，閒點數-莊點數=N，若N=4~9，則閒龍寶勝出；`,
      "d81": `例牌定義：首兩牌結果為8或9點；`,
      "d82": `非例牌定義：例牌之外的所有情況，詳見賠率表。`,
      "d83": `4.16. 超和：`,
      "d84": `莊閒為和的情況下，和的點數，包括 “和0~ 和9” 共十個超級和的點數；`,
      "d85": `例如：遊戲中，莊家、閒家開牌結果均為0時，即押注 “超和0” 者為勝；`,
      "d86": `5. 賠率表`,
      "d87": `百家樂遊戲中，提供百家樂玩法和免佣百家樂玩法。兩種遊戲規則一致，但投注莊的派彩方式有區別：`,
      "d88": `百家樂`,
      "d89": `投注項目`,
      "d90": `賠率`,
      "d91": `閒`,
      "d92": `1:1 <br> (開和局時退回下注金額)`,
      "d93": `莊`,
      "d94": `非免佣1:0.95 <br> (開和局時退回下注金額)`,
      "d95": `免佣1:1 <br> (如莊以6點取勝，則賠一半；開和局時退回下注金額)`,
      "d96": `和`,
      "d97": `閒對`,
      "d98": `莊對`,
      "d99": `超級對`,
      "d100": `完美對子`,
      "d101": `任意對子`,
      "d102": `龍7`,
      "d103": `熊貓8`,
      "d104": `大老虎`,
      "d105": `小老虎`,
      "d106": `老虎和`,
      "d107": `老虎對`,
      "d108": `1:100 <br> 計莊對、閒對，且為相同牌面對子`,
      "d109": `1:20 <br> 計莊對、閒對`,
      "d110": `1:4 <br> 計莊對或閒對`,
      "d111": `莊天牌&閒天牌`,
      "d112": `天牌`,
      "d113": `龍&虎`,
      "d114": `龍虎和時退一半下注`,
      "d115": `龍虎和`,
      "d116": `超級六`,
      "d117": `1:12 <br> 莊以兩張牌，6點勝出`,
      "d118": `1:20 <br> 莊以三張牌，6點勝出`,
      "d119": `龍寶`,
      "d120": `例牌和局,退回本金`,
      "d121": `退本金`,
      "d122": `以例牌勝出`,
      "d123": `以非例牌贏4點`,
      "d124": `以非例牌贏5點`,
      "d125": `以非例牌贏6點`,
      "d126": `以非例牌贏7點`,
      "d127": `以非例牌贏8點`,
      "d128": `以非例牌贏9點`,
      "d129": `超和`,
      "d130": `超和（0）`,
      "d131": `超和（1）`,
      "d132": `超和（2）`,
      "d133": `超和（3）`,
      "d134": `超和（4）`,
      "d135": `超和（5）`,
      "d136": `超和（6）`,
      "d137": `超和（7）`,
      "d138": `超和（8）`,
      "d139": `超和（9）`,
      "d140": `6. 遊戲特點`,
      "d141": `採用最得人心的8副牌（去掉大小王共416張）玩法。獨有的博牌規則，正如人生，不計一時，只要投入，總有翻身機會。`,
      "d142": `7. 注意事項`,
      "d143": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "d144": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "d145": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "d146": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "d147": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`,
      "d148": `遊戲規則`,
      "d149": `博牌牌例`,
      "d150": `點數計算`,
      "d151": `玩法判定`,
      "d152": `賠率表`,
      "d153": `遊戲特點`,
      "d154": `注意事項`
    },
    "DragonTiger": {
      "d1": `龍虎`,
      "d2": `1. 遊戲規則`,
      "d3": `遊戲採用8副牌（去大小王），共416張牌；`,
      "d4": `只派2次牌，先龍後虎，各派1張，無需博牌；`,
      "d5": `開牌後按照兩家牌點數比大小。`,
      "d6": `2. 點數計算`,
      "d7": `牌面A為1；`,
      "d8": `牌面2-10點數和牌面相同，即2-10；`,
      "d9": `J，Q，K分別為11，12，13。`,
      "d10": `3. 玩法判定`,
      "d11": `龍和虎比牌，龍大則龍贏，虎大則虎贏；`,
      "d12": `如龍虎點數相同，即為和局。`,
      "d13": `4. 賠率表`,
      "d14": `投注項目`,
      "d15": `賠率`,
      "d16": `龍`,
      "d18": 0.04236111111111111,
      "d19": `開和局時，退回一半下注金額`,
      "d20": `虎`,
      "d21": 0.04236111111111111,
      "d22": `開和局時，退回一半下注金額`,
      "d23": `和`,
      "d24": 0.04722222222222222,
      "d25": `5. 遊戲特點`,
      "d26": `相對於百家樂，無需博牌，單張鬥大。規則簡單，上手容易，無論男女老幼均可快速上手體驗遊戲快樂。`,
      "d27": `6. 注意事項`,
      "d28": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "d29": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "d30": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "d31": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "d32": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`,
      "d33": `遊戲規則`,
      "d34": `點數計算`,
      "d35": `玩法判定`,
      "d36": `賠率表`,
      "d37": `遊戲特點`,
      "d38": `注意事項`
    },
    "Bulls": {
      "d1": `牛牛`,
      "d2": `1. 遊戲規則`,
      "d3": `一副牌52張（去大小王）；`,
      "d4": `發牌排序為莊→閒1→閒2→閒3循環，但要用頭牌決定先發哪一張牌；`,
      "d5": `三家閒分別與莊家進行比牌，先比較牌型，若牌型相同，再比較各自最大一張牌的大小，若牌面相同，最後比較花色。`,
      "d6": `2. 點數計算`,
      "d7": `牌面A為1；牌面10，J，Q，K為10點；牌面2-9點數和牌面相同；`,
      "d8": `每家有5張牌，存在三張相加為10的倍數，則為有牛。反之即是無牛；`,
      "d9": `牌型中有牛，剩下兩張相加，個位數是1-9則為對應的牛幾。為0即是牛牛；`,
      "d10": `如5張牌全是J/Q/K組成，則牌型為五公。`,
      "d11": `3. 玩法判定`,
      "d12": `牛牛中有莊，閒1，閒2，閒3四家牌；`,
      "d13": `下注盤則分為閒1，閒2，閒3三門，每門包含莊平倍，莊翻倍，莊超倍，閒平倍，閒翻倍，閒超倍；`,
      "d14": `開牌後，以莊和三家閒比大小。莊大押莊贏。閒大押閒贏；`,
      "d15": `牌型：五公＞牛牛＞牛9＞牛8＞牛7＞牛6＞牛5＞牛4＞牛3＞牛2＞牛1＞無牛；`,
      "d16": `大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；`,
      "d18": `花色：黑桃＞紅心＞梅花＞方塊；`,
      "d19": `押平倍，輸贏平倍。押翻倍，則輸贏多倍。`,
      "d20": `4. 賠率表`,
      "d21": `具體賠率倍數取決於贏家的牌型。押平倍輸贏平倍，押翻倍輸贏多倍。`,
      "d22": `玩家贏賠率`,
      "d23": `勝方牌型`,
      "d24": `平倍`,
      "d25": `翻倍`,
      "d26": `五公`,
      "d27": `牛牛`,
      "d28": `牛`,
      "d29": `無牛`,
      "d30": `玩家輸賠率`,
      "d31": `勝方牌型`,
      "d32": `平倍`,
      "d33": `翻倍`,
      "d34": `五公`,
      "d35": `牛牛`,
      "d36": `牛7-牛9`,
      "d37": `無牛-牛6`,
      "d38": `“翻倍”投注與派彩範例1：`,
      "d39": `押注對象`,
      "d40": `1家`,
      "d41": `投注玩法`,
      "d42": `閒翻倍`,
      "d43": `投注額度`,
      "d44": `100，實際扣除500，記錄中將顯示為“100（預扣400）”`,
      "d45": `比牌結果`,
      "d46": `莊家牌：牛8 | 1家牌：牛5`,
      "d47": `派彩`,
      "d48": `退回300（勝方牌型牛8/，對應玩家輸的賠率2，1家輸100×2=200）`,
      "d49": `“翻倍”投注與派彩範例2：`,
      "d50": `押注對象`,
      "d51": `1家`,
      "d52": `投注玩法`,
      "d53": `莊翻倍`,
      "d54": `投注額度`,
      "d55": `100，實際扣除500，記錄中將顯示為“100（預扣400）”`,
      "d56": `比牌結果`,
      "d57": `莊家牌：牛8 | 1家牌：牛5`,
      "d58": `派彩`,
      "d59": `690（退回500+100*勝方牌型牛8對應的玩家贏的賠率1.90=690）`,
      "d60": `5. 遊戲特點`,
      "d61": `流行於湖廣一帶的紙牌遊戲。玩家可自由對莊閒下注。翻倍玩法會因勝方牌型而出現多種賠率，精彩刺激！`,
      "d62": `6. 注意事項`,
      "d63": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "d64": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "d65": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "d66": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "d67": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`,
      "d68": `遊戲規則`,
      "d69": `點數計算`,
      "d70": `玩法判定`,
      "d71": `賠率表`,
      "d72": `遊戲特點`,
      "d73": `注意事項`,
      "d74": `超倍`
    },
    "SanGong": {
      "d1": `三公`,
      "d2": `1. 遊戲規則`,
      "d3": `一副牌52張（去大小王）；`,
      "d4": `發牌排序為莊→閒1→閒2→閒3循環，但要用頭牌決定先發哪一張牌；`,
      "d5": `三家閒和莊牌比大小，閒大則閒贏，莊大則閒輸。`,
      "d6": `2. 牌型大小`,
      "d7": `遊戲中，花牌J，Q，K為公牌`,
      "d8": `玩法`,
      "d9": `說明（牌型大小由大到小，由上至下排列）`,
      "d10": `三公`,
      "d11": `J，Q，K為公，三張牌全由公牌組成，即為三公`,
      "d12": `雙公9`,
      "d13": `2張牌為公，另外1張為9點`,
      "d14": `單公9`,
      "d15": `1張牌為公，另外兩張點數合計，個位數為9點`,
      "d16": `9點`,
      "d18": `3張牌點數合計，個位數為9點`,
      "d19": `雙公8`,
      "d20": `2張牌為公，另外1張為8點`,
      "d21": `單公8`,
      "d22": `1張牌為公，另外兩張點數合計，個位數為8點`,
      "d23": `8點`,
      "d24": `3張牌點數合計，個位數為8點`,
      "d25": `雙公7`,
      "d26": `2張牌為公，另外1張為7點`,
      "d27": `單公7`,
      "d28": `1張牌為公，另外兩張點數合計，個位數為7點`,
      "d29": `7點`,
      "d30": `3張牌點數合計，個位數為7點`,
      "d31": `雙公6`,
      "d32": `2張牌為公，另外1張為6點`,
      "d33": `單公6`,
      "d34": `1張牌為公，另外兩張點數合計，個位數為6點`,
      "d35": `6點`,
      "d36": `3張牌點數合計，個位數為6點`,
      "d37": `雙公5`,
      "d38": `2張牌為公，另外1張為5點`,
      "d39": `單公5`,
      "d40": `1張牌為公，另外兩張點數合計，個位數為5點`,
      "d41": `5點`,
      "d42": `3張牌點數合計，個位數為5點`,
      "d43": `雙公4`,
      "d44": `2張牌為公，另外1張為4點`,
      "d45": `單公4`,
      "d46": `1張牌為公，另外兩張點數合計，個位數為4點`,
      "d47": `4點`,
      "d48": `3張牌點數合計，個位數為4點`,
      "d49": `雙公3`,
      "d50": `2張牌為公，另外1張為3點`,
      "d51": `單公3`,
      "d52": `1張牌為公，另外兩張點數合計，個位數為3點`,
      "d53": `3點`,
      "d54": `3張牌點數合計，個位數為3點`,
      "d55": `雙公2`,
      "d56": `2張牌為公，另外1張為2點`,
      "d57": `單公2`,
      "d58": `1張牌為公，另外兩張點數合計，個位數為2點`,
      "d59": `2點`,
      "d60": `3張牌點數合計，個位數為2點`,
      "d61": `雙公1`,
      "d62": `2張牌為公，另外1張為1點`,
      "d63": `單公1`,
      "d64": `1張牌為公，另外兩張點數合計，個位數為1點`,
      "d65": `1點`,
      "d66": `3張牌點數合計，個位數為1點`,
      "d67": `雙公0`,
      "d68": `2張牌為公，另外1張為0點`,
      "d69": `單公0`,
      "d70": `1張牌為公，另外兩張點數合計，個位數為0點`,
      "d71": `0點`,
      "d72": `3張牌點數合計，個位數為0點`,
      "d73": `3. 對牌以上牌型`,
      "d74": `同花順：`,
      "d75": `3張牌為連續且同花的牌，包含A，2，3和Q，K，A；`,
      "d76": `三條:`,
      "d77": `3張牌為牌面相同的牌；`,
      "d78": `順子:`,
      "d79": `3張牌為連續，不計花色，含A，2，3和Q，K，A；`,
      "d80": `同花:`,
      "d81": `手牌中3張為同花，但面值不連續；`,
      "d82": `對子`,
      "d83": `手牌中3張其中兩張牌面相同，即為對子。`,
      "d84": `3. 玩法判定`,
      "三公中有庄，闲1，闲2，闲3四家牌；": `三公中有莊，閒1，閒2，閒3四張牌；`,
      "下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；": `下注盤則分為閒1，閒2，閒3三門，每門包含莊平倍，莊翻倍，莊超倍，閒平倍，閒翻倍，閒超倍；`,
      "开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；": `開牌後，以莊和三家閒比大小。莊大押莊贏。閒大押閒贏；`,
      "大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；": `大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；`,
      "花色：黑桃＞红心＞梅花＞方块；": `花色：黑桃＞紅心＞梅花＞方塊；`,
      "押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。": `押平倍，輸贏平倍。押翻倍，則輸贏多倍。押超倍，則輸贏多倍。`,
      "d91": `4. 賠率表`,
      "d92": `賠率分為莊閒輸贏賠率，和特殊牌型賠率。`,
      "d93": `開牌結果`,
      "d94": `賠率`,
      "d95": `閒（贏）`,
      "d96": 0.04236111111111111,
      "d97": `閒（輸）`,
      "d98": 0.04236111111111111,
      "d99": `和`,
      "d100": `三公`,
      "d101": `牌型`,
      "d102": `賠率`,
      "d103": `同花順`,
      "d104": `三條`,
      "d105": `順子`,
      "d106": `同花`,
      "d107": `對子`,
      "d108": `如牌型同時滿足多個結果，則以賠率高的結果為準；`,
      "d109": `例如♠️2 ♠️3 ♠️4，同時滿足同花順和順子，同花順賠率較高，則以同花順賠率派彩。`,
      "d110": `無抽水的設計，但下列兩種狀況有特殊賠率：`,
      "d111": `投注贏，閒家以6點的任意牌型組合勝出，則獲1賠0.5；`,
      "d112": `投注輸，莊家以6點的任意牌型組合勝出，則獲得1賠0.5。`,
      "d113": `5. 遊戲特點`,
      "d114": `三公（3 Face）是一種傳統民間玩法，下注簡單，氣氛熱烈，不需要複雜操作和計算，拼的就是運氣，在新興的遊戲類型中頗受歡迎。`,
      "d115": `6. 注意事項`,
      "d116": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "d117": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "d118": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "d119": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "d120": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`,
      "d121": `遊戲規則`,
      "d122": `牌型大小`,
      "d123": `對牌以上牌型`,
      "d124": `玩法判定`,
      "d125": `賠率表`,
      "d126": `遊戲特點`,
      "d127": `注意事項`,
      "玩家赢赔率": `玩家贏賠率`,
      "玩家输赔率": `玩家輸賠率`,
      "胜方牌型": `勝方牌型`,
      "平倍": `平倍`,
      "翻倍": `翻倍`,
      "超倍": `超倍`,
      "三条": `三條`,
      "3条3": `3條3`,
      "点": `點`
    },
    "GoldenFlower": {
      "龙凤炸金花": `龍鳳炸金花`,
      "游戏规则": `遊戲規則`,
      "使用一副牌游戏（去大小王）进行游戏；": `使用一副牌遊戲（去大小王）進行遊戲；`,
      "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小（不看花色），决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。": `先比較牌型大小，若牌型一致再按從大到小的順序依次比較牌面大小，決定勝負。若龍、鳳的三張牌大小一致，則為和局，退回玩家在本局下注龍、鳳的籌碼。`,
      "玩法判定": `玩法判定`,
      "牌型": `牌型`,
      "豹子杀手：": `豹子殺手：`,
      "三张非同花的2，3，5；": `三張非同花的2，3，5；`,
      "豹子：": `豹子：`,
      "三张点数相同的牌；": `三張點數相同的牌；`,
      "同花顺：": `同花順：`,
      "花色相同的顺子牌；": `花色相同的順子牌；`,
      "同花：": `同花`,
      "花色相同的任意三张牌；": `花色相同的任意三張牌；`,
      "顺子：": `順子：`,
      "三张连续的牌；": `三張連續的牌；`,
      "对子：": `對子：`,
      "两张点数相同的牌；": `兩張點數相同的牌；`,
      "散牌：": `散牌：`,
      "无法组成任何类型的牌；": `無法組成任何類型的牌；`,
      "牌型大小": `牌型大小`,
      "豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `豹子＞同花順＞同花＞順子＞對子＞散牌；`,
      "豹子杀手＞豹子；": `豹子殺手＞豹子；`,
      "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `當豹子牌和豹子殺手牌同時存在時，豹子殺手牌比豹子牌大；`,
      "散牌＞豹子杀手；": `散牌＞豹子殺手；`,
      "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `如不存在豹子牌，豹子殺手是最小的散牌牌型。`,
      "对子大小": `對子大小`,
      "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `若兩家都是對子牌，即比對對子點數大小，對A＞對K＞對Q＞對J＞對10＞對9＞對8＞對7＞對6＞對5＞對4＞對3＞對2；`,
      "两对对子的点数相同，再比单牌点数大小。": `兩對對子的點數相同，再比單牌點數大小。`,
      "豹子、同花、散牌牌型": `豹子、同花、散牌牌型`,
      "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。": `比最大那張牌的點數，如最大的那張點數相同，則比第二大牌的點數，以此類推；A>K>Q>J>10>9>8>7>6>5>4> 3>2，花色不分大小。`,
      "同花顺，顺子": `5. 同花順，順子`,
      "按照顺序比点数：AKQ>KQJ>......>432>32A": `按照順序比點數：AKQ>KQJ>......>432>32A`,
      "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `注意：只有在同花順和順子牌型中，A可作為最大或最小的牌，其餘牌型中A為最大的單牌`,
      "赔率表": `賠率表`,
      "投注项目": `投注項目`,
      "规则": `規則`,
      "赔率": `賠率`,
      "龙": `龍`,
      "龙胜出": `龍勝出`,
      "凤": `鳳`,
      "凤胜出": `鳳勝出`,
      "对8以上": `對8以上`,
      "龙或凤以对8以上的牌型胜出": `龍或鳳以對8以上的牌型勝出`,
      "顺子": `順子`,
      "龙或凤以顺子的牌型胜出": `龍或鳳以順子的牌型勝出`,
      "同花": `同花`,
      "龙或凤以同花的牌型胜出": `龍或鳳以同花的牌型勝出`,
      "同花顺": `同花順`,
      "龙或凤以同花顺的牌型胜出": `龍或鳳以同花順的牌型勝出`,
      "豹子": `豹子`,
      "龙或凤以豹子的牌型胜出": `龍或鳳以豹子的牌型勝出`,
      "游戏特点": `遊戲特點`,
      "龙凤炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。": `龍鳳炸金花，也被稱為贏三張。是一種廣泛流傳的民間遊戲。最大特色除常規押注龍，鳳。也可對贏家牌型進行投注。甚至任何對8以上牌型都為贏的玩法。可謂由簡至繁，滿足各階層玩家。`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "TexasHoldem": {
      "德州扑克": `德州撲克`,
      "游戏规则": `遊戲規則`,
      "采用1副牌（去大小王），共计52张；": `採用1副牌（去大小王），共52張；`,
      "首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；": `首先發出5張公共牌，然後發給閒家2張手牌，莊家2張手牌；`,
      "投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；": `投注倒計時結束後，荷官會首先翻開閒家2張手牌和前3張公共牌；`,
      "然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。": `然後進入跟注階段，閒家需要決定是否跟注；如果選擇跟注，則需要在跟注區域投注相當於底注2倍的籌碼。如果不跟注，則放棄本局，輸掉底注。`,
      "跟注环节结束后，荷官翻开后2张公共牌；": `跟注環節結束後，荷官翻開後2張公共牌；`,
      "玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。": `玩家透過自己的2張手牌和5張公共牌組成最大的5張牌，跟莊家比大小。`,
      "AA边注玩法": `AA邊注玩法`,
      "AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。": `AA邊注是可選的。可用於投注最先發的5張牌（玩家的兩張牌和前三張公共牌）有沒有一對A或更好的牌。`,
      "玩法判定": `玩法判定`,
      "牌型": `牌型`,
      "皇家同花顺": `皇家同花順`,
      "皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `皇家同花順是指包括A、K、Q、J、10這樣大牌的同花順。如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "同花顺": `同花順`,
      "同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `同花順是指五張連續數值的同花牌，比如：9、8、7、6、5，而且全部都是紅心。兩個同花順相比，大牌面的勝出。如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。": `註：同花A、2、3、4、5為最小的同花順；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不為同花順。`,
      "四条": `四條`,
      "四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。": `四條是指一手牌中包含四張花色同一數值的牌，再加上一張其他牌。比如說，您拿到了四種花色的A，那就是四條。大牌值的四條大於小牌值的四條。`,
      "葫芦": `葫蘆`,
      "葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。": `葫蘆是指一手牌中有三種花色的同值牌，還有兩種花色的另一同值牌，例如三張K和兩張6。在兩手葫蘆牌中，三張同值牌面較大的一家勝出。`,
      "同花": `同花`,
      "同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `同花是指一手牌中的所有牌都是同一花色，但不是連續的，例如五張梅花。在兩手同花中，根據每手牌中最大牌面的大小決定輸贏。如果兩手牌中最大牌面大小相同，那麼以第二大牌面的大小決定輸贏，以此類推直到分出輸贏。如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "顺子": `順子`,
      "顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `順子是指一手牌中五張牌都是連續的，但不是同種花色，例如非同花9、8、7、6、5。兩手順子相比，大牌面的一手勝出。如果兩手順子牌面值相同，花色不作為決定輸贏的條件；如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。": `注：A、2、3、4、5為最小的順子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不為順子。`,
      "三条": `三條`,
      "三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。": `三條是指一手牌中包含三張花色的同值牌，還有兩張非同值牌。比如說，如果玩家拿到了三條K，那就稱之為三條。大牌面的三條勝出小牌面的三條。`,
      "两对": `兩對`,
      "两对以下": `兩對以下`,
      "两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `兩對是指一手牌中包含兩張同值牌，還有兩張另一牌值的同值牌（牌面相等，但是與第一個對子牌面不相等），還有一張其他牌面的牌。一手牌中包含兩張A和兩張K就是兩對。對於兩手兩對牌，比較各手牌中牌面較大的對子，大牌面的一手勝出。如果兩手牌中較大牌面對子大小相同，則比較第二個對子。如果兩個對子大小都相同看，則根據另外一張單牌決定輸贏。如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "一对": `一對`,
      "对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `對子是指一手牌中包含兩張同值牌（例如兩張K），再加上三張其它非同值牌。牌值較大的對子勝過較小的對子。如果兩手牌對子的牌面大小相同，則降序比較其它三張牌。如果出現平局，玩家拿回初始賭注（底注和跟注），遊戲結果為平局。`,
      "高牌": `高牌`,
      "高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。": `高牌是指不屬於上述任何一種牌型的一手牌。不屬於任何牌型。`,
      "牌型大小": `牌型大小`,
      "皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌": `皇家同花順＞同花順＞四條＞葫蘆＞同花＞順子＞三條＞兩對＞一對＞高牌`,
      "庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。": `莊家最後的牌型必須為對4或以上，才有資格與閒家牌型比對；否則視為閒家贏。`,
      "输赢判定和赔率表": `輸贏判定和賠率表`,
      "底注、跟注玩法赔率（不含本金）如下：": `底注、跟注玩法賠率（不含本金）如下：`,
      "输赢": `輸贏`,
      "底注": `底注`,
      "跟注": `跟注`,
      "庄家没有资格(对4或以下),玩家胜": `莊家沒有資格(對4或以下),玩家勝`,
      "赔付表": `賠付表`,
      "返回本金": `返回本金`,
      "庄家有资格(对4或以上),玩家胜": `莊家有資格(對4或以上),玩家勝`,
      "庄家有资格(对4或以上),平局": `莊家有資格(對4或以上),平局`,
      "玩家不跟注(弃牌)": `玩家不跟注(棄牌)`,
      "输": `輸`,
      "庄家有资格(对4或以上),庄家胜": `莊家有資格(對4或以上),莊家勝`,
      "底注玩法赔率（不含本金）如下：": `底注玩法賠率（不含本金）如下：`,
      "手牌": `手牌`,
      "赔率": `賠率`,
      "AA边注玩法赔率（不含本金）如下：": `AA邊注玩法賠率（不含本金）如下：`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "SicBo": {
      "骰宝": `骰寶`,
      "游戏规则": `遊戲規則`,
      "荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳；": `荷官啟動開關，機器產生3次或3次以上震動，透明骰盅中三顆6面骰子將在震動作用下彈跳；`,
      "当停止震动，骰子落下时，取其上面显现的点数计数；": `當停止震動，骰子落下時，取其上面顯現的點數計數；`,
      "和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；": `和三顆骰子所表現點數，組合，或和值相符合的玩法勝出；`,
      "如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。": `如震動停止後，三顆骰子有任意顆底面沒有完全接觸骰蠱最底面，或出現重疊狀態，或震動次數少於3次，將會再次觸發震動重新獲取結果。`,
      "玩法判定": `玩法判定`,
      "大/小": `大/小`,
      "三颗骰子总点数11-17为大，4-10为小；": `三顆骰子總點數11-17為大，4-10為小；`,
      "出现围骰，庄家通吃，大/小均输。": `出現圍骰，莊家通吃，大/小均輸。`,
      "单/双": `單/雙`,
      "三颗骰子和值，分为单双两种情况；": `三顆骰子和數值，分為單雙兩種情況；`,
      "总点数为5，7，9，11，13，15，17为单；": `總點數為5，7，9，11，13，15，17為單；`,
      "总点数为4，6，8，10，12，14，16为双；": `總點數為4，6，8，10，12，14，16為雙；`,
      "出现围骰，庄家通吃，单/双均输。": `出現圍骰，莊家通吃，單/雙均輸。`,
      "单点": `單點`,
      "根据所选择点数相符的骰子出现次数判断；": `根據所選擇點數相符的骰子出現次數判斷；`,
      "有一个骰子符合，则为单骰；": `有一個骰子符合，則為單骰；`,
      "有两个骰子符合，则为双骰；": `有兩顆骰子符合，則為雙骰；`,
      "有三个骰子符合，则为三骰。": `有三個骰子符合，則為三骰。`,
      "和值": `和值`,
      "三个骰子的点数之和为和值；": `三個骰子的點數和為和值；`,
      "和值范围为4-17点。不同和值具有不同赔率（详见赔率）。": `和值範圍為4-17點。不同和值具有不同賠率（詳見賠率）。`,
      "牌九式": `牌九式`,
      "三颗骰子中，任意两颗骰子满足特定组合条件；": `三顆骰子中，任意兩顆骰子滿足特定組合條件；`,
      "总共15种开出的三颗骰子任意两个满足即可胜出。": `總共15種開出的三顆骰子任意兩個滿足即可勝出。`,
      "对子": `對子`,
      "三颗骰子中，任意两颗骰子点数相同即为对子；": `三顆骰子中，任意兩顆骰子點數相同即為對子；`,
      "在6种对子玩法中押中，即可胜出。": `在6種對子玩法中押中，即可勝出。`,
      "全围": `全圍`,
      "三颗骰子点数相同即全围；": `三顆骰子點數相同即全圍；`,
      "包含6种围骰组合，出现任意一种即可胜出。": `包含6種圍骰組合，出現任意一種即可勝出。`,
      "围骰": `圍骰`,
      "投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。": `投注特定的一種圍骰，即三顆骰子點數完全一樣。滿足條件即可勝出。`,
      "赔率表": `賠率表`,
      "玩法赔率": `玩法賠率`,
      "投注项目": `投注項目`,
      "赔率": `賠率`,
      "大": `大`,
      "小": `小`,
      "单骰": `單骰`,
      "双骰": `雙骰`,
      "三骰": `三骰`,
      "对子（双骰、长牌）": `對子（雙骰、長牌）`,
      "牌九式（骨牌、短牌）": `牌九式（骨牌、短牌）`,
      "点数和为4或17": `點數和為4或17`,
      "点数和为5或16": `點數和為5或16`,
      "点数和为6或15": `點數和為6或15`,
      "点数和为7或14": `點數和為7或14`,
      "点数和为8或13": `點數和為8或13`,
      "点数和为9、10、11或12": `點數和為9、10、11或12`,
      "单": `單`,
      "双": `雙`,
      "游戏特点": `遊戲特點`,
      "是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。": `是古老的中國骰子遊戲，玩家透過猜測骰子開出的點數，總和，組合等，具備多元化投注種類，以及極具吸引力的賠率，極受中國乃至亞洲玩家歡迎。`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "SeDie": {
      "色碟": `色碟`,
      "游戏规则": `遊戲規則`,
      "荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。": `荷官將4顆紅白雙色的鈕扣放入碟中，蓋上碗後上下搖碟；完成搖碟動作後靜置於畫面中供會員下注。下注時間結束將碗打開，依開出結果派彩。`,
      "玩法说明": `玩法說明`,
      "大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码": `大：開出(4個紅色)、(3個紅色，1個白色鈕扣)屬「大」。二紅二白視為和局，退回玩家下注籌碼`,
      "小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码": `小：開出(4個白色)、(3個白色，1個紅色鈕扣)屬「小」。二紅二白視為平手，退回玩家下注籌碼`,
      "单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；": `單：開出(3個紅色，1個白色鈕扣)、(3個白色，1個紅色鈕扣)屬「單」；`,
      "双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；": `雙：開出(4個紅色鈕扣)、(4個白色鈕扣)、(2個白色、2個紅色鈕扣)屬「雙」；`,
      "4（4红）：开出4个红色钮扣。": `4（4紅）：開出4個紅色鈕扣。`,
      "0（4白)：开出4个白色钮扣。": `0（4白)：開出4個白色鈕扣。`,
      "3(3红1白)：开出3个红色，1个白色钮扣。": `3(3紅1白)：開出3個紅色，1個白色鈕扣。`,
      "1(3白1红)：开出3个白色，1个红色钮扣。": `1(3白1紅)：開出3個白色，1個紅色鈕扣。`,
      "赔率表": `賠率表`,
      "玩法赔率": `玩法賠率`,
      "下注组合": `下注組合`,
      "赔率": `賠率`,
      "大": `大`,
      "小": `小`,
      "单": `單`,
      "双": `雙`,
      "4(4红)": `4(4紅)`,
      "0(4白)": `0(4白)`,
      "3(3红1白)": `3(3紅1白)`,
      "1(3白1红)": `1(3白1紅)`,
      "特别说明": `特別說明`,
      "本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。": `本遊戲進行方式為最接近實境賭場之設置，若有發生特殊情形將依本公司公告之辦法處理。`,
      "荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。": `荷官在遊戲進行中若不慎讓碗、碟或鈕扣掉落，將物件放回定位後，重新進行搖碟。`,
      "若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。": `若開出結果時有鈕扣重疊無法判定結果時，荷官將以玻璃棒分開重疊的鈕扣。`,
      "荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。": `荷官若沒有搖碟就開碟，或在下注時間尚未結束前即開碟，該局將判定為無效，並退還所有下注本金。`,
      "荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。": `荷官搖碟後開碟過程:如出現【開碟中的碗觸碰到鈕扣】，最終以開碟後鈕扣倒下的顏色為最終結果。`,
      "注意事项": `注意事項`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "AndarBahar": {
      "安达巴哈": `安達巴哈`,
      "游戏规则": `遊戲規則`,
      "安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；": `安達巴哈是一款非常簡單的紙牌遊戲，該遊戲使用1副牌（去大小王）；`,
      "荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；": `荷官在小丑牌位置上發一張牌，牌面朝上，然後繼續在A（安達）和B（巴哈）點上發牌，牌面朝上；`,
      "出现一张牌和小丑牌的面值相同时，游戏结束。": `出現一張牌和小丑牌的面值相同時，遊戲結束。`,
      "玩法判定": `玩法判定`,
      "安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。": `安達或巴哈哪家先發出和小丑牌面值相同時，哪家獲勝。`,
      "赔率表": `賠率表`,
      "玩法赔率": `玩法賠率`,
      "投注项目": `投注項目`,
      "赔率": `賠率`,
      "安达": `安達`,
      "巴哈": `巴哈`,
      "游戏特点": `遊戲特點`,
      "安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。": `安達巴哈是印度最受歡迎的紙牌遊戲之一，它的流行在於它的簡單性，您只有兩種玩法選擇，投注簡單快速。`,
      "注意事项": `注意事項`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "blackJack": {
      "21点": `21點`,
      "游戏简述": `遊戲簡述`,
      "21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。": `21點又名黑傑克（Blackjack），起源於法國，已流傳到世界各地，有著悠久的歷史。該遊戲使用除大小王以外的52張牌，遊戲者的目標是使手中的牌的點數總和不超過21點且盡量大，然後和荷官比大小。`,
      "玩法规则": `玩法規則`,
      "游戏规则": `遊戲規則`,
      "7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。": `7個主玩家坐在賭桌上（可有無限數量的旁注玩家）。 21點的目標是讓您的牌比莊家的牌更接近21點，但不能超過21點。在21點中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，數字牌按照面值算。 Blackjack比21點大。`,
      "如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。": `如果您的頭兩張牌是一張A和一張點數為10的牌，則您獲得了Blackjack，此時如果莊家不是Blackjack,您將贏得自己賭注的1.5倍。 （如您的賭注是10，則您會收到25）。`,
      "出现一张牌和小丑牌的面值相同时，游戏结束。": `出現一張牌和小丑牌的面值相同時，遊戲結束。`,
      "玩法判定": `玩法判定`,
      "如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。": `如果您的牌的總點數比莊家的更接近21點，您將贏得您的賭注金額（如果您的賭注是10，您則收到20）。`,
      "如果您的牌的总点数超过21点，您“爆牌”并失去赌注。": `如果您的牌的總點數超過21點，您“爆牌”並失去賭注。`,
      "如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。": `如果您和莊家的總點數相同（17點及以上），則雙方都不算贏，您的賭注也會退回，算作平手。`,
      "庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。": `莊家拿牌到16點，在點數達到或超過17後才能停牌（包括軟17）。`,
      "游戏流程": `遊戲流程`,
      "投注主赌注后，可以投注边注玩法（完美对子、21+3）。": `投注主賭注後，可以投注邊注玩法（完美對子、21+3）。`,
      "投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。": `投注輪結束後，使用要牌、停牌、加倍、分牌和保險（如果莊家的第一張牌是A）按鈕來參與遊戲。`,
      "如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。": `如果您沒有選擇操作，您將會自動停牌。如果您選擇加倍，則會從餘額中拿出與主賭注相等的金額並將您的主賭注翻倍。`,
      "要牌": `要牌`,
      "选择“要牌”继续游戏并再发一张牌。": `選擇“要牌”繼續遊戲並再發一張牌。`,
      "停牌": `停牌`,
      "选择“停牌”则该手牌的当前回合结束。": `選擇“停牌”則該手牌的目前回合結束。`,
      "分牌": `分牌`,
      "如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。": `如果您的前兩張牌點數相同（註：10、J、Q、K均為10點），您可將其分成兩手獨立的牌，並投放與原賭注相同的第二份賭注。然後您可為每手牌分別要牌。分牌後不能再次分牌。`,
      "对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。": `對於分出的每手牌，您都可以要任意數目的牌；但如果將兩張A分牌，您只能為每張A再拿一張牌。如果您在分牌後得到一張A和一張點數為十的牌，那麼這樣的牌只能算作21點，而不算作Blackjack。這種情況下的賠付是1：1,而不是1：1.5。`,
      "注：分牌后不可以投注完美对子玩法。": `註：分牌後不可以投注完美對子玩法。`,
      "加倍": `加倍`,
      "在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。": `在獲發前兩張牌後，如果您認為靠第三張牌可以贏過莊家的牌，則您可以將賭注加倍。賭注將變成原賭注的兩倍（差額從您的餘額中扣除），並且您將再獲發一張牌。`,
      "您可以在分牌后加倍。": `您可以在分牌後加倍。`,
      "如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。": `如果玩家加倍，莊家的第一張牌點數是A,且莊家獲得Blackjack，則您將輸掉兩份賭注（初始賭注和加倍賭注）。`,
      "如果庄家第一张牌点数是10，且庄家获得Blackjack，则加倍赌注返还给您。": `如果莊家第一張牌點數是10，且莊家獲得Blackjack，則加倍賭注返還給您。`,
      "保险": `保險`,
      "如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。": `如果莊家的初始牌為A，您將可以投注保險，以防莊家獲得Blackjack。保險投注金額為底注的一半。`,
      "如果庄家的确有Blackjack，您将按1：2获得保险赔付。": `如果莊家的確有Blackjack，您將以1：2取得保險給付。`,
      "如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。": `如果莊家沒拿到Blackjack，無論您最終輸或者贏，您將輸掉自己的保險投注。`,
      "10牌自动胜": `10牌自動勝`,
      "理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。": `理論上，您有可能要10張牌但仍未爆牌(一副手牌）。這種情況下，您的手牌將自動獲勝，但莊家拿到Blackjack時除外。`,
      "多座位功能": `多座位功能`,
      "我们提供单座模式桌台和双座模式桌台。": `我們提供單座模式桌台和雙座模式桌台。`,
      "在单座模式桌台内，每个玩家最多可以使用一个座位。": `在單座模式桌台內，每位玩家最多可以使用一個座位。`,
      "在双座模式内，玩家可以占据最多2个座位，并在每个座位上分别投注。": `在雙座模式內，玩家可以佔據最多2個座位，並在每個座位上分別投注。`,
      "如果玩家在赌桌上没有已确认的赌注，玩家可以在两局之间或单独游戏局中更换座位。": `如果玩家在賭桌上沒有已確認的賭注，玩家可以在兩局之間或單獨遊戲局中更換座位。`,
      "如果玩家在赌桌上有已确认的赌注，玩家仅可以占据和离开没有投注的座位。": `如果玩家在賭桌上有已確認的賭注，玩家僅可以佔據和離開沒有投注的座位。`,
      "旁注功能（座位后投注）": `旁注功能（座位後投注）`,
      "本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。": `本遊戲提供旁注功能。這樣您可以參與遊戲，只需跟隨主玩家的操作，對他們的手牌下注。`,
      "只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。": `只有被主玩家佔據的位置上才有旁注選項。您可以同時成為多個位置上的旁注玩家。作為主玩家參與遊戲時，您也可以在除自己位置外的所有其他位置上作為旁注玩家投注。`,
      "作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。": `作為旁注玩家，您會自動跟隨主玩家的操作，除非他們選擇分牌或加倍，而您沒有足夠的資金這樣做。這種情況下，如果主玩家加倍，您會拿牌並停牌；如果主玩家分牌，則按右主手牌操作。`,
      "如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。": `如果您對一個玩家的手牌進行旁注，但該玩家在投注輪決定不下注，您的賭注將返還到您的帳戶。`,
      "热门玩家图标帮助您了解当前哪位玩家连胜最高。": `熱門玩家圖標幫助您了解當前哪位玩家連勝最高。`,
      "数字表示该玩家连胜的局数。如果该玩家输了一局，他将失去热门玩家图标。": `數字表示該玩家連勝的局數。如果該玩家輸了一局，他將失去熱門玩家圖標。`,
      "发牌方式": `發牌方式`,
      "我们采用欧式发牌方式。": `我們採用歐式發牌方式。`,
      "庄家在所有玩家都做出决定后获得第二张牌。": `莊家在所有玩家都做出決定後獲得第二張牌。`,
      "发牌方式会影响保险和操作回合的顺序：": `發牌方式會影響保險和操作回合的順序：`,
      "对于欧式发牌，当您获得第二张牌且庄家的第一张牌是A时，将提供保险选项。不过您会在保险回合后直接进入操作回合，所有玩家都操作完毕后会将第二张牌发给庄家。": `對於歐式發牌，當您獲得第二張牌且莊家的第一張牌是A時，將提供保險選項。不過您會在保險回合後直接進入操作回合，所有玩家都操作完畢後會將第二張牌發給莊家。`,
      "关于21点游戏桌台，我们存在以下2种:": `關於21點遊戲桌台，我們存在以下2種:`,
      "1.使用8副牌透明牌靴的桌台，其标志为": `1.使用8副牌透明牌靴的桌台，其標誌為`,
      "2.使用6副牌洗牌机的桌台，其标志为": `2.使用6副牌洗牌機的桌台，其標誌為`,
      "边注": `邊註`,
      "这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。": `這些是主遊戲以外的玩法，必須投注主賭注後才可投注這些玩法。`,
      "21+3": `21+3`,
      "玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。": `玩家可以對發給玩家的前兩張牌連同發給莊家的第一張牌下注。`,
      "同花三条：所有三张牌的点数和花色都相同": `同花三條：所有三張牌的點數和花色都相同`,
      "同花顺：所有三张牌花色相同、点数依次排列": `同花順：所有三張牌花色相同、點數依次排列`,
      "三条：所有三张牌大小相同": `三條：所有三張牌大小相同`,
      "顺子：所有三张牌花色不同但点数依次排列": `順子：所有三張牌花色不同但點數依次排列`,
      "同花：所有三张牌花色相同": `同花：所有三張牌花色相同`,
      "同花三条": `同花三條`,
      "(完全相同的三张牌)赔率 1：100": `(完全相同的三張牌)賠率 1：100`,
      "同花顺": `同花順`,
      "（同花顺子,三张同花顺序）赔率 1：40": `（同花順子,三張同花順序）賠率 1：40`,
      "三条": `三條`,
      "（三张相同牌值的非同花牌）赔率 1：30": `（三張相同牌值的非同花牌）賠率 1：30`,
      "顺子": `順子`,
      "（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10": `（非同順子,三張不同花順序,包含Q、K、A牌型）賠率 1：10`,
      "同花": `同花`,
      "（三张同一花色的牌）赔率 1：5": `（三張同一花色的牌）賠率 1：5`,
      "以玩家首2张牌算": `以玩家首2張牌算`,
      "完美对子": `完美對子`,
      "（闲家一对同色同花同点值）赔率 1：25": `（閒家一對同色同花同點值）賠率 1：25`,
      "同色对子": `同色對子`,
      "（闲家一对同色同点值不同花）赔率 1：12": `（閒家一對同色同點值不同花）賠率 1：12`,
      "混合对子": `混合對子`,
      "（闲家一对不同色不同花同点值）赔率 1：6": `（閒家一對不同色不同花同點值）賠率 1：6`,
      "赔率表": `賠率表`,
      "玩法赔率": `玩法賠率`,
      "主游戏": `主遊戲`,
      "赔率": `賠率`,
      "投注项目": `投注項目`,
      "主赌注": `主賭注`,
      "Blackjack": `Blackjack`,
      "21+3边注玩法": `21+3邊注玩法`,
      "完美对子边注玩法": `完美對子邊注玩法`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。": `所有入座玩家必須先下注某個位置的底注才能下注該位置的完美對子和21+3玩法。`,
      "如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。": `如果賭桌上的每位玩家均爆牌（點數高於21點），那麼這局遊戲結束；莊家不需要拿牌。如果賭桌上的每位玩家均爆牌（點數高於21點），但莊家底牌為A,莊家需要拿1牌，以判斷莊家是否為BJ。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。": `荷官發牌時，偶有系統無法判讀狀況時，此時荷官將重新掃描，直到系統能完整判讀（如遇故障將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`,
      "若玩家已经是Blackjack后出现系统故障，该玩家仍然按照1:1.5进行派彩。": `若玩家已經是Blackjack後出現系統故障，則該玩家仍依照1:1.5進行派彩。`,
      "如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。": `如果您從遊戲中斷開，任何已確定賭注將保持有效，並在您不在場的時候結算。重新連線後，您可以在“投注記錄”中查看結果。`
    },
    "Roulette": {
      "轮盘": `輪盤`,
      "游戏规则": `遊戲規則`,
      "荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；": `荷官轉動輪盤，並拋出象牙製滾球到輪盤外側；`,
      "当滚球在轮盘内转动多周后减速并落入下方格子；": `當滾球在輪盤內轉動多週後減速並落入下方格子；`,
      "该格子所对应的数字，作为该局结果；": `該格子所對應的數字，作為該局結果；`,
      "若滚球在轮盘转动小于或等于5圈，需要重新打球。": `若滾球在輪盤轉動小於或等於5圈，需要重新打球。`,
      "玩法判定": `玩法判定`,
      "美式注盘": `美式注盤`,
      "美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。": `美式下注盤也叫經典盤，包含了直注以及各種組合下注。玩家可在遊戲中切換美式和法式下注盤。`,
      "美式-直注": `美式-直注`,
      "即直接点击数字方块，对0-36的数字直接下注。": `即直接點擊數字方塊，對0-36的數字直接下注。`,
      "例如，下注11点，只有当结果是11时才会胜出。": `例如，下注11點，只有當結果是11時才會勝出。`,
      "美式-分注": `美式-分注`,
      "即在线上下注，当结果为线两边的数字时胜出。": `即在線上下注，當結果為線兩邊的數字時勝出。`,
      "例如，下注3和6之间的线，或者2和3之间的线。": `例如，下注3和6之間的線，或者2和3之間的線。`,
      "美式-三数": `美式-三數`,
      "三数为同时投注包含0在内的三个数字。": `三數為同時投注包含0在內的三個數字。`,
      "例如，0，1，2为一组。0，2，3为一组。": `例如，0，1，2為一組。 0，2，3為一組。`,
      "美式-四号": `美式-四號`,
      "同时对0，1，2，3四个数字进行下注。": `同時對0，1，2，3四個數字進行下注。`,
      "美式-角注": `美式-角注`,
      "对4个数字方框交叉处点击。": `對4個數字方框交叉處點擊。`,
      "例如：1，2，4，5为一注。2，3，5，6为一注。": `例如：1，2，4，5為一注。 2，3，5，6為一注。`,
      "美式-街注": `美式-街注`,
      "对3个相连的数字投注。": `對3個相連的數字投注。`,
      "例如：1，2，3为一组。7，8，9为一组。": `例如：1，2，3為一組。 7，8，9為一組。`,
      "美式-线注": `美式-線注`,
      "类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。": `類似街注，區别在於對2個街注相交的位置下注，同時下注6個數字。`,
      "例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。": `例如：1，2，3，4，5，6一注。 4，5，6，7，8，9一注。`,
      "美式-列注": `美式-列注`,
      "每列12个号码，对“第X列”处下注。": `每列12個號碼，對“第X列”處下注。`,
      "例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。": `例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12個數字。`,
      "美式-打注": `美式-打注`,
      "将筹码下到“第X打”的位置。": `將籌碼下到“第X打”的位置。`,
      "例如：第一打包括1-12。第二打为13-24。第三打为25-36。": `例如：第一打包括1-12。第二打為13-24。第三打為25-36。`,
      "美式-红黑": `美式-紅黑`,
      "对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。": `對桌台上標註紅，黑的位置下注。若開0則通殺，紅/黑均輸。`,
      "例如：所有为红色格子的数字或所有黑色格子的数字。": `例如：所有為紅色格子的數字或所有黑色格子的數字。`,
      "美式-单双": `美式-單雙`,
      "对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。": `對桌台上標注單，雙的位置下注。若開0則通殺，單/雙均輸。`,
      "例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。": `例如：單數包括1，3，5……35的單數。雙則表示2，4，6……36的雙數。`,
      "美式-大小": `美式-大小`,
      "对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。": `對桌台上標注大，小的位置下注。若開0則通殺，大/小均輸。`,
      "例如：小（1-18）。大（19-36）。": `例如：小（1-18）。大（19-36）。`,
      "法式注盘": `法式注盤`,
      "也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。": `也叫歐式注盤，可視為對美式注盤的衍生。將美式注盤的一部分打包組合後一起下注的玩法。`,
      "法式-相邻投注：": `法式-相鄰投注：`,
      "点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。": `點擊法式輪盤的格子，同時對點擊格子左鄰和右鄰兩個號碼（一共5號碼），按順序各下一注。`,
      "例如：选中5，则投注号码为23，10，5，24，16。": `例如：選中5，則投注號碼為23，10，5，24，16。`,
      "法式-轮上零旁：": `法式-輪上零旁：`,
      "点击轮上零旁区域。": `點擊輪上零旁區域。`,
      "对0和3；12和15；32和35；26；各下一注。": `對0和3；12和15；32和35；26；各下一注。`,
      "法式-零旁注上角：": `法式-零旁注上角：`,
      "点击零旁注上角区域，对以下区域和组合进行下注。": `點擊零旁注上角區域，對以下區域和組合進行下注。`,
      "4和7；12和15；18和21；19和22；32和35；5个组合各一注；": `4和7；12和15；18和21；19和22；32和35；5個組合各一注；`,
      "0，2，3的组合，下两注；": `0，2，3的組合，下兩注；`,
      "25，26，28，29的组合，下两注。": `25，26，28，29的組合，下兩注。`,
      "法式-轮上孤注：": `法式-輪上孤注：`,
      "点击轮盘孤注，对以下区域组合进行下注。": `點擊輪盤孤注，對以下區域組合進行下注。`,
      "即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。": `即輪盤枱上的1號，6號，9號；14號，17號；17號，20號；31號，和34號幾種組合各投1注。`,
      "法式-轮盘下角注：": `法式-輪盤下角注：`,
      "法式-轮盘下角注。": `法式-輪盤下角注。`,
      "5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。": `5和8；10和11；13和16；23和24；27和30；33和36這六個玩法下1注。`,
      "小球落入的格子对应信息包含数字，红黑。数字分大小，单双；": `小球落入的格子對應信息包含數字，紅黑。數字分大小，單雙；`,
      "同时根据数字在下注盘的位置，分为“打”和“列”；": `同時根據數字在下注盤的位置，分為“打”與“列”；`,
      "也有根据数字所在下注盘位置，分为不同组的玩法。": `也有根據數字所在下注盤位置，分為不同組別的玩法。`,
      "赔率表": `賠率表`,
      "由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。": `由於法式下注盤只是對美式下注盤的玩法打包，其賠率對應到美式下注盤，因此賠率以美式為主。`,
      "投注项目": `投注項目`,
      "赔率": `賠率`,
      "直注": `直注`,
      "分注": `分注`,
      "街注": `街注`,
      "三数": `三數`,
      "角注": `角注`,
      "四号": `四號`,
      "线注": `線注`,
      "列注": `列注`,
      "打注": `打注`,
      "红/黑": `紅/黑`,
      "单/双": `單/雙`,
      "大/小": `大/小`,
      "游戏特点": `遊戲特點`,
      "欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。": `歐美常見的競猜遊戲，一度風靡於皇族和貴族之間，現代社會才步入平民娛樂。被譽為最高貴的遊戲。以下注玩法多維度著稱，單押直注追求高賠率，或多點投入追求中獎率，休閒與刺激兼顧。`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "Fantan": {
      "番摊": `番攤`,
      "游戏规则": `遊戲規則`,
      "游戏使用钮扣200颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。": `遊戲使用鈕扣200顆，新局開始後，荷官用鐵碗從鈕扣堆中取出不知數的鈕扣，開始下注。`,
      "下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。": `下注結束後，荷官即翻開鐵碗收皮，用一個棒子將鈕扣每4個為一列排好，直到剩下4或少於4個為止。`,
      "最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。": `最後剩下的鈕扣數即為本局開獎結果，畫面會顯示玩家的輸贏狀況。`,
      "玩法判定": `玩法判定`,
      "单：1、3为单；": `單：1、3為單；`,
      "双：2、4为双。": `雙：2、4為雙。`,
      "番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；": `番：投注單一號碼，開出為贏，其餘為輸。如：1番，開1為贏，2、3、4為輸；`,
      "念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；": `念：投注兩個號碼，一個為贏，一個為和，另外兩個為輸 如：1念2，開1為贏，開2為和，3、4為輸；`,
      "角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；": `角：投注兩個號碼，開出為贏，其餘為輸。如：1.2角，開1、2為贏，3、4為輸；`,
      "通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；": `通：投注兩個號碼，開出為贏，其餘兩個號碼一輸一和。如：12三通，則開1、2為贏，3為輸，4為和；`,
      "三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；": `三門：投注三個號碼，如果開出的結果是三個號碼之一，則中獎；其他則輸。如：三門123，開1、2、3為贏，4為輸；`,
      "赔率表": `賠率表`,
      "玩法赔率": `玩法賠率`,
      "投注项目": `投注項目`,
      "赔率": `賠率`,
      "单": `單`,
      "双": `雙`,
      "番": `番`,
      "念": `念`,
      "角": `角`,
      "通": `通`,
      "三门": `三門`,
      "游戏特点": `遊戲特點`,
      "番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。": `番攤是中國古老的做莊遊戲，流行於兩廣一帶，當年廣攤只有「番」一種玩法，故名番攤，時至今日，番攤玩法已改變不少，除了番之外還多了念、角、通、門、單雙這幾種玩法。`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    },
    "FriedGoldenFlower": {
      "炸金花": `炸金花`,
      "游戏规则": `遊戲規則`,
      "使用一副牌游戏（去大小王）进行游戏；": `使用一副牌遊戲（去大小王）進行遊戲；`,
      "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。": `先比較牌型大小，若牌型一致再依由大到小的順序依序比較牌面大小，決定勝負。`,
      "玩法判定": `玩法判定`,
      "牌型": `牌型`,
      "豹子杀手：": `豹子殺手：`,
      "三张非同花的2，3，5；": `三張非同花的2，3，5；`,
      "豹子：": `豹子：`,
      "三张点数相同的牌；": `三張點數相同的牌；`,
      "同花顺：": `同花順：`,
      "花色相同的顺子牌；": `花色相同的順子牌；`,
      "同花：": `同花：`,
      "花色相同的任意三张牌；": `花色相同的任意三張牌；`,
      "顺子：": `順子：`,
      "三张连续的牌；": `三張連續的牌；`,
      "对子：": `對子：`,
      "两张点数相同的牌；": `兩張點數相同的牌；`,
      "散牌：": `散牌：`,
      "无法组成任何类型的牌；": `無法組成任何類型的牌；`,
      "牌型大小": `牌型大小`,
      ">豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `豹子＞同花順＞同花＞順子＞對子＞散牌；`,
      "豹子杀手＞豹子；": `豹子殺手＞豹子；`,
      "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `當豹子牌和豹子殺手牌同時存在時，豹子殺手牌比豹子牌大；`,
      "散牌＞豹子杀手；": `散牌＞豹子殺手；`,
      "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `如不存在豹子牌，豹子殺手是最小的散牌牌型。`,
      "对子大小": `對子大小`,
      "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `若兩家都是對子牌，即比對對子點數大小，對A＞對K＞對Q＞對J＞對10＞對9＞對8＞對7＞對6＞對5＞對4＞對3＞對2；`,
      "两对对子的点数相同，再比单牌点数大小。": `兩對對子的點數相同，再比單牌點數大小。`,
      "豹子、同花、散牌牌型": `豹子、同花、散牌牌型`,
      "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2。": `比最大那張牌的點數，如最大的那張點數相同，則比第二大牌的點數，以此類推；A>K>Q>J>10>9>8>7>6>5>4>3>2。`,
      "同花顺，顺子": `同花順，順子`,
      "按照顺序比点数：AKQ>KQJ>......>432>32A": `按照順序比點數：AKQ>KQJ>......>432>32A`,
      "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `注意：只有在同花順和順子牌型中，A可作為最大或最小的牌，其餘牌型中A為最大的單牌`,
      "赔率表": `賠率表`,
      "玩家赢赔率": `玩家贏賠率`,
      "玩家输赔率": `玩家輸賠率`,
      "胜方牌型": `勝方牌型`,
      "平倍": `平倍`,
      "翻倍": `翻倍`,
      "超倍": `超倍`,
      "单牌": `單牌`,
      "单牌以下": `單牌{value}以下`,
      "对子": `對子`,
      "顺子": `順子`,
      "同花": `同花`,
      "同花顺": `同花順`,
      "三条": `豹子`,
      "豹子杀手": `豹子殺手`,
      "游戏特点": `遊戲特點`,
      "炸金花，也被称为赢三张。是一种广泛流传的民间游戏。": `炸金花，也被稱為贏三張。是一種廣泛流傳的民間遊戲。`,
      "注意事项": `注意事項`,
      "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `部分玩法可能會存在本靴指定局數可投注限制，詳情請查看遊戲內相關提示。`,
      "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `桌台限紅是本遊戲桌台主要玩法的最小、最大玩法限紅。`,
      "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `荷官開牌時，偶有系統無法識別狀況，此時荷官將操作系統嘗試重新識別，直到系統能正確識別（如遇故障因素將取消所有相關注單/派彩）。`,
      "如遇结算错误，按照当期视频结果重新结算。": `如遇結算錯誤，按照當期視頻結果重新結算。`,
      "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `如遊戲過程中因遊戲平台網路等故障造成未能正常完成牌局，系統將取消整局玩家下注並退回下注本金。`
    }
  };
  export default data;